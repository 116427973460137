<template>
<div class="video-container">
    <video ref="player" controls @play="play" @ended="ended" @error="error" @playing="playing"></video>

    <div class="playlist" v-if="files.length > 1">
        <div class="playlist-container">
            <button :class="{ disabled: currentIndex === 0 }" @click="playPrevious">
                <svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'>
                    <title>ionicons-v5-a</title>
                    <polyline points='328 112 184 256 328 400' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px' />
                </svg>
            </button>
            <span>Videos {{ currentIndex + 1}} of {{ files.length }}</span>
            <button :class="{ disabled: (currentIndex + 1) === files.length }" @click="playNext">
                <svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'>
                    <title>ionicons-v5-a</title>
                    <polyline points='184 112 328 256 184 400' style='fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px' />
                </svg>
            </button>
        </div>
    </div>
</div>
</template>
<script>
import $analytics from "@/services/analytics";

export default {
    props: [
        'files',
        'analyticsId',
        'analyticsAction'
    ],

    data() {
        return {
            currentIndex: 0,
            video: null,
        }
    },

    mounted() {
        this.video = this.$refs.player;
        this.init();
    },

    methods: {
        play() {
            this.video.play();
            this.trackPlay();
            if(window.cordova){
                screen.orientation.unlock();
            }
        },

        error(e) {
            console.log("ERROR", e);
        },

        ended() {
            this.playNext();
            if(window.cordova){
                screen.orientation.lock("portrait");
            }
        },

        init() {
            this.video.src = this.files[this.currentIndex];
        },

        playNext() {
            if (this.files.length > (this.currentIndex + 1)) {
                this.currentIndex++;
                this.video.src = this.files[this.currentIndex];
                this.video.play();
            }
        },

        playPrevious() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.video.src = this.files[this.currentIndex];
                this.video.play();
            }
        },

        trackPlay() {
            $analytics.track(this.analyticsAction, this.analyticsId);
        },

        playing() {
            if(window.cordova){
                screen.orientation.unlock();
            }
        }

    },
}
</script>

<style scoped>
.video-container {
    width: 100%;
}

video {
    background-color: #000000;
    width: 100%;
    height: 300px;
}

.playlist {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #efefef;
    color: #000000;
    padding: 5px 10px;
}

.playlist span {
    margin-left: 10px;
    margin-right: 10px;
}

.playlist svg {
    width: 30px;
    height: 30px;
}

.playlist button {
    background-color: transparent;
    outline: none;
}

.disabled {
    opacity: 0.3;

}

.playlist-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
