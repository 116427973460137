<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ translate.labels["app.testimonies.title"] }}</ion-title>
        <ion-button @click="markAllAsRead" slot="end" size="small" fill="clear" >
          {{ translate.labels["app.general.markAllAsUnread"] }}
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <AppTestimonies></AppTestimonies>
    </ion-content>
  </ion-page>
</template>

<script>
  import $history from "@/services/history";
  import $analytics from "@/services/analytics";
  import AppTestimonies from "@/components/AppTestimonies";
  import { mapState } from 'vuex';

  export default{
    components:{
      AppTestimonies
    },

    computed: {
      ...mapState({
        translate: state => state.translations.translate,
        news: state => state.global.news
      }),
    },

    created() {
      $analytics.track("news-list");
    },

    methods: {
      markAllAsRead() {
        let unreadNews = this.news.filter((news) => {
          return news.read === false;
        });

        unreadNews.forEach((news) => {
          $history.markRead("news." + news.id);
        });

        this.$store.commit('global/markAllTestimoniesRead');
      }
    },
  }
</script>

<style scoped>
  .btn {
    max-width: 110px;
    outline: none;
  }
</style>
