<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>KHẢI TƯỢNG</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">MỘT Đức Chúa Trời</div>
      <div class="brand-words">MỘT Hội Thánh</div>
      <div class="brand-words">MỘT Word Lời Chúa</div>
    </div>
    <h3>SỨ MẠNG</h3>
    <ol>
      <li>Thể hiện sự hiệp nhất khi mọi tín hữu cùng nhau lắng nghe Lời Chúa</li>
      <li>Cuộc đời biến đổi và phục hưng</li>
      <li>Môn đệ hóa thông qua thói quen nghe Kinh Thánh hằng ngày</li>
      <li>Vươn đến những người chưa đến với Đấng Christ</li>
      <li>Hoàn thành  Khải Tượng 2033: 99% dân số thế giới có thể nghe Kinh Thánh trong ngôn ngữ thân thuộc của họ.</li>
    </ol>
    <p>
      <span>Chúng ta cùng nhau hoàn thành Khải Tượng 2033:</span>
      <a @click="openLink('https://www.faithcomesbyhearing.com/about/our-mission')">https://www.faithcomesbyhearing.com/about/our-mission</a>
      <span>đến năm 2033, thu âm Lời Chúa trong mọi ngôn ngữ cần thiết và cung cấp miễn phí trên toàn thế giới!</span>
     </p>
     <h3>CHÚNG TÔI LÀ AI</h3>
     <p>Ra đời từ một hạt cải nhỏ bé vào năm 2019, Chiến dịch ONE Hearing the Word (Chiến dịch ONE) được đồng sáng lập bởi Faith Comes By Hearing Asia (FCBH Châu Á) và Hong Kong Evangelical Fellowship (HKEF)*. Chiến dịch ONE, là một chiến dịch tập hợp hàng trăm Hội Thánh ở Hồng Kông để nghe Kinh Thánh trong 47 ngày của Mùa Chay nhằm bày tỏ sự hiệp nhất của các tín hữu dưới Lời Chúa, và chứng minh rằng các Cơ đốc nhân là MỘT Hội Thánh và chỉ có Một Thân thể của Đấng Christ.</p>
     <p>Khi chúng ta cùng nhau lắng nghe và suy gẫm Lời Chúa, thì những tấm lòng sẽ được động chạm, những tâm linh được phấn khởi, sẽ có một sự phục hưng và biến đổi của đời sống, vì chúng ta được nhắc nhở về sự kêu gọi để trở thành chứng nhân cho đến tận cùng trái đất.</p>
     <p>Được truyền cảm hứng từ Khải tượng của Chiến dịch ONE, Mục sư Tiến sĩ Randy Frazee, Mục sư Trưởng của Hội Thánh Westside Family và Tiến sĩ Daniel KL Chua, Giáo sư  m nhạc tại Đại học Hồng Kông đã đáp ứng với Sự kêu gọi vào năm 2021 bằng cách tập hợp các thành phố trên thế giới cùng tham gia vào Chiến dịch ONE, và sáng tác bài hát chủ đề cho Chiến dịch ONE (được gọi là Bài hát ONE - HIỆP MỘT). Vinh quang thuộc Chúa, Chiến dịch ONE hiện đã trở thành một phong trào toàn cầu!</p>
     <p>Vào năm 2021, 32 thành phố ở khắp nơi trên thế giới đã tham gia Chiến dịch ONE, bao gồm cả những thành phố từ các quốc gia Châu Á - Singapore, Malaysia, Việt Nam - và Ghana từ Châu Phi.</p>
     <p>Bài hát HIỆP MỘT bám sát với Kinh Thánh, mở ra cuộc hành trình sử thi từ Đại Mạng Lệnh trong Ma-thi-ơ 28 đến Giê-ru-sa-lem Mới trong Khải Huyền 22, để bày tỏ sự hợp nhất của Đức Chúa Trời và Hội thánh Ngài, và cách mà sự hợp nhất đó làm động lực cho khải tượng và sứ mệnh của Vương quốc Đức Chúa Trời.</p>
     <p>Với những nỗ lực phối hợp của Faith Comes By Hearing khu vực Châu Á, từ năm 2023 sẽ có thêm ba ngôn ngữ khác (tiếng Mã Lai, tiếng Tamil và tiếng Việt) được thêm vào ứng dụng “ONE Hearing the Word” (ONE).</p>
     <iframe src="https://iframe.mediadelivery.net/embed/100722/710ec432-a6ee-4aa8-a6f5-92c306752d05"></iframe>

     <h3>Bạn Có Thể Hỗ Trợ Theo 3 Cách</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>Chia Sẻ</h4>
       <p>Chia sẻ khải tượng này với bạn bè và giúp sức quảng bá trên các phương tiện truyền thông xã hội</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>Cầu Nguyện</h4>
       <p>Cùng chúng tôi hiệp trong sự cầu nguyện để công tác mà Đức Chúa Trời đã khởi sự làm cho Khải Tượng 2033 được hoàn thành trong thế hệ này.</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>Dâng Hiến</h4>
       <p>Dâng Hiến  cách rời rộng và vui mừng và góp phần để thay đổi thế giới cho Đấng Christ.</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">Dâng Hiến</ion-button>

     </div>

     <h3>Để biết thêm thông tin</h3>
     <p>Xin truy cập vào website: www.onehtw.com, và liên hệ với chúng tôi qua email: hello@faithcomesbyhearing.asia để biết thêm thông tin về Chiến dịch ONE.</p>

     <h4>Faith Comes By Hearing (FCBH)</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>FCBH Châu Á là một phần của gia đình FCBH Albuquerque, Hoa Kỳ, là một mục vụ ghi âm Kinh thánh quốc tế và có sẵn các bản thu Kinh thánh thuộc hơn 1.700 ngôn ngữ. Trong 50 năm, mục vụ đã cam kết đáp ứng cho mọi người, đặc biệt là người nghèo và người mù chữ khả năng tiếp cận một cách miễn phí với Lời của Chúa thông qua ngôn ngữ thân thuộc với họ. Từ các công nghệ trực tuyến và di động tiên tiến đến các thiết bị chạy bằng năng lượng mặt trời, tài nguyên của mục vụ hiện đang được sử dụng ở hầu hết các quốc gia trên thế giới. Thông qua Ứng dụng Kinh Thánh phổ biến, BIBLE.IS, mục vụ cam kết và đáp ứng cung cấp toàn bộ bản ghi âm Lời Chúa thuộc mọi ngôn ngữ được cập nhật. Một trong những nhiệm vụ của FCBH là hiện thực hóa Tầm nhìn 2033, nhằm mục đích phổ biến Lời Chúa cho 99,9% dân số thế giới để mọi ngôn ngữ và mọi bộ tộc có thể biết rằng Chúa Giê-xu là Chúa. FCBH cũng là tổ chức từ thiện được xếp hạng hàng đầu trên Charity Navigator trong hơn 10 năm liên tiếp.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
