<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title v-if="campaign">{{ campaign.name }}</ion-title>
        <ion-button
          slot="end"
          size="small"
          fill="clear"
          @click="markAllAsRead"
        >
          {{ translate.labels["app.general.markAllAsUnread"] }}
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-spinner v-if="!campaign"></app-spinner>
      <ion-list v-if="campaign">
        <!--  :class="{unread:!devotional.read}" -->
        <ion-item v-for="(devotional,index) in campaign.devotionals" :key="index" v-router-link="{name:'devotional',params:{date:devotional.date}}" lines="none">
          <ion-label>
            <h3>{{devotional.day}}: {{devotional.title}}</h3>
            <p>{{devotional.date | moment("ll")}}</p>
          </ion-label>
          <ion-badge slot="end" v-if="!devotional.read">{{ translate.labels["app.general.unreadStatus"] }}</ion-badge>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script>
import $api from "@/services/api";
import $history from "@/services/history";
import $analytics from "@/services/analytics";
import AppSpinner from "@/components/AppSpinner";
import { mapState } from 'vuex';

export default {
  components:{
    AppSpinner
  },

  data() {
    return {
      campaign: null
    }
  },

  created() {
    this.refreshData();
    $analytics.track("list-page-view");
  },

  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },

  methods:{
    refreshData() {
      $api.get("/campaigns/" + this.$route.params.id).then((campaign)=>{
        campaign.devotionals.forEach((devotional,d)=>{
          devotional.day = this.translate.labels["app.general.date"].replace("#",(d+1));
          devotional.read = $history.hasRead("devo." + devotional.date, devotional.date);
        })
        this.campaign = campaign;
      })
    },

    markAllAsRead() {
      let unreadDevotionals = this.campaign.devotionals.filter((devotional) => {
        return devotional.read === false;
      });

      unreadDevotionals.forEach((devotional) => {
        $history.markRead("devo." + devotional.date);
      });

      this.refreshData();
    }
  },
}
</script>

<style scoped>
  .btn {
    max-width: 100px;
    font-size: 12px;
    outline: none;
  }

</style>
