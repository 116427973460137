<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ translate.labels["app.settings.church"] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label class="one-text-wrap">
          <p>{{ translate.labels["app.welcome.introChurch"] }}</p>
        </ion-label>
      </ion-item>
      <ChurchSelector @change="next()"></ChurchSelector>
    </ion-content>
  </ion-page>
</template>
<script>
import $analytics from "@/services/analytics";
import $api from "@/services/api";
import ChurchSelector from "@/components/ChurchSelector";
import { mapState } from 'vuex';

export default {
  components:{
    ChurchSelector
  },
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods:{
    next:function(){
      this.$router.push({name:"home"});
    }
  },
  created:function(){
    //$analytics.track("church-settings-view");
  }
}
</script>
