<template>
  <div>
    <app-spinner v-if="!languages"></app-spinner>
    <ion-list v-if="languages">
      <ion-item v-for="(lang, index) in languages" :key="index">
        <ion-label>{{ lang.name }}</ion-label>
        <ion-radio
          slot="end"
          @ionSelect="setLanguage(lang)"
          :checked="(selectedLanguage == lang.id)"
        ></ion-radio>
      </ion-item>
    </ion-list>
  </div>
</template>
<script>
import $api from '@/services/api';
import $analytics from '@/services/analytics';
import AppSpinner from '@/components/AppSpinner';

export default {
  components: {
    AppSpinner
  },
  data() {
    return {
      selectedLanguage: null,
      languages: null,
    };
  },
  methods:{
    async refreshData() {
      this.languages = await $api.get('/languages');
      console.log('Languages', this.languages);
    },
    async setLanguage(lang) {
      localStorage.language = this.selectedLanguage = lang.id;
      $analytics.track('language-settings-change');

      const labels = await $api.get('/labels');
      this.$store.commit('translations/setLabels', labels);

      // Todo - store this in the language database?
      const momentMap = {
        ENG: 'en',
        TGL: 'tl-ph',
        KOR: 'ko',
        IND: 'id',
        YUH: 'zh-tw',
        CHN: 'zh-cn',
        VIE: 'vi',
        MAY: 'ms',
        TAM: 'en',
      }
      const momentId = momentMap[lang.id];
      this.$moment.locale(momentId);
      this.$emit('change');
    }
  },
  created() {
    this.refreshData();
    this.selectedLanguage = localStorage.language;
  },
}
</script>
