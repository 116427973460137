<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ translate.labels['app.settings.title'] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item v-router-link="{ name: 'language-settings' }" lines="full">
        <ion-label>{{ translate.labels['app.settings.language'] }}</ion-label>
      </ion-item>
      <ion-item v-router-link="{ name: 'country-settings' }" lines="full">
        <ion-label>{{ translate.labels['app.settings.country'] }}</ion-label>
      </ion-item>
      <ion-item v-router-link="{ name: 'church-settings' }" lines="full">
        <ion-label>{{ translate.labels['app.settings.church'] }}</ion-label>
      </ion-item>
      <ion-item v-router-link="{ name: 'preferences' }" lines="full">
        <ion-label>{{ translate.labels['app.settings.preferences'] }}</ion-label>
      </ion-item>
      <ion-item v-router-link="{ name: 'about' }" lines="full">
        <ion-label>{{ translate.labels['app.settings.about'] }}</ion-label>
      </ion-item>
      <ion-item @click="resetApp()" lines="full">
        <ion-label>{{ translate.labels['app.settings.reset'] }}</ion-label>
      </ion-item>
      <ion-item @click="invite()" lines="full">
        <ion-label>{{ translate.labels['app.countdown.invite'] }}</ion-label>
      </ion-item>
      <p class="version-info">2023v07</p>
    </ion-content>
  </ion-page>
</template>
<script>
import $analytics from '@/services/analytics';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
       translate: 'translate',
    }),
  },
  methods:{
    async resetApp() {
      const a = await this.$ionic.alertController.create({
         header: this.translate.labels['app.settings.reset'],
         message: this.translate.labels['app.settings.resetConfirmation'],
         buttons: [
           {
             text:  this.translate.labels['app.general.cancelButton'],
             role: 'cancel',
             cssClass: 'secondary'
           },
           {
             text:  this.translate.labels['app.general.confirmButton'],
             handler: () => {
               $analytics.track('reset-app');
               localStorage.clear();
               window.location.href = './';
             },
           },
         ],
       });
       a.present();
    },
    async invite() {
      $analytics.track('invite-friends');
      const message = this.translate.labels['app.countdown.invitationText'];
      const url = 'http://go.onehtw.com';
      if( !window.plugins || !window.plugins.socialsharing ){
        const a = await this.$ionic.alertController.create({
          header: 'Error',
          message: 'Sharing is disabled on this device',
          buttons: ['OK']
        });
        a.present();
      }
      else {
        window.plugins.socialsharing.shareWithOptions({
          message,
          url,
        }, console.log, console.log);
      }
    }
  },
  created() {
    $analytics.track('settings-page-view');
  },
};
</script>
<style scoped>
.version-info {
  color:#ccc;
  padding:8px;
  text-align:center;
}
</style>
