<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ translate.labels['app.list.title'] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-spinner v-if="!campaigns"></app-spinner>
      <ion-list v-if="campaigns">
        <ion-item v-for="(campaign,index) in campaigns" :key="index" v-router-link="{name:'campaign-item',params:{id:campaign.id}}" lines="none">
          <ion-label class="one-text-wrap">
            <h3>{{campaign.name}}</h3>
            <div class="message">{{campaign.start_date | moment("ll") }} - {{campaign.end_date | moment("ll") }}</div>
          </ion-label>
          <ion-badge slot="end" v-if="campaign.unreadCount">{{campaign.unreadCount}}</ion-badge>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script>
import $api from '@/services/api';
import $history from '@/services/history';
import $analytics from '@/services/analytics';
import AppSpinner from '@/components/AppSpinner';
import { mapState } from 'vuex';

export default {
  components:{
    AppSpinner
  },
  data:function(){
    return {
      campaigns:null
    }
  },
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    })
  },
  methods:{
    async refreshData() {
      const campaigns = await $api.get('/campaigns');
      this.campaigns = campaigns.map((campaign) => {
        campaign.unreadCount = (campaign.devotionals.filter((r) => {
          return !$history.hasRead('devo.' + r.date, r.date);
        })).length;
        return campaign;
      });
    }
  },
  created(){
    this.refreshData();
    $analytics.track('campaign-list-view');
  }
}
</script>
<style scoped>
.message {
  color: var(--ion-color-medium);
}
</style>
