<template>
  <div class="app-spinner">
    <ion-spinner></ion-spinner>
  </div>
</template>
<script>
  export default {
    name:"app-spinner"
  }
</script>
<style scoped>
  .app-spinner
  {
    text-align: center;
    padding:4px;
  }
</style>
