<template>
  <div>
    <app-spinner v-if="!churches" />
    <ion-searchbar
      v-if="churches"
      @input="query = $event.target.value"
      :placeholder="translate.labels['app.general.searchPlaceholder']"
    ></ion-searchbar>
    <ion-list v-if="churches">
      <ion-radio-group @ionChange="handleSelectChurch($event)">
        <ion-item v-for="(church, index) in displayChurches" :key="index">
          <ion-label>
            <div v-if="church.name_eng">{{ church.name_eng }}</div>
            <div v-if="church.name_local">{{ church.name_local }}</div>
          </ion-label>
          <ion-radio slot="end" :value="church.id"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <ion-infinite-scroll id="infinite-scroll" @ionInfinite="loadData($event)">
    <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>
<script>
import $api from '@/services/api';
import $analytics from '@/services/analytics';
import AppSpinner from '@/components/AppSpinner';
import { mapState } from 'vuex';

export default {
  components:{
    AppSpinner
  },
  data() {
    return {
      query: '',
      churches: null,
      limit: 50,
      tempListChurches: [],
    };
  },
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
    displayChurches() {
      this.tempListChurches = this.churches.filter((church) => {
        if (church.id == 'other') {
          return true;
        }
        if (localStorage.country && church.country && (church.country != localStorage.country)) {
          return false;
        }
        if (!this.query) {
          return true;
        }
        const keywords = this.query.split(' ');
        const searchableName = [ church.name_eng, church.name_local ].join(' ');
        return keywords.every((keyword) => {
          return searchableName.toLowerCase().includes(keyword.toLowerCase());
        })
      });
      return this.tempListChurches.slice(0, this.limit);
    }
  },
  methods: {

    async refreshData() {
      const churches = await $api.get('/churches');
      this.churches = churches;
      this.churches.unshift({
        name_eng: this.translate.labels['app.welcome.otherChurch'],
        name: this.translate.labels['app.welcome.otherChurch'],
        _id: 'other'
      });
      this.tempListChurches = this.churches.slice(0, this.limit);
    },

    handleSelectChurch(event) {
      localStorage.church = event.detail.value;
      this.$emit('change', event.detail.value);
      $analytics.track('church-settings-change');
    },

    async loadData(event) {
      await this.$nextTick();
      this.limit = this.limit + 50
      this.tempListChurches = this.churches.slice(0, this.limit);
      event.target.complete();
    },
  },
  created() {
    this.refreshData();
  },
}
</script>
