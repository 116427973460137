import $history from '@/services/history.js';

export default {

  setLaunchAppStatus(state, val) {
    state.launchApp = val;
  },

  // News
  setNews(state, val) {
    state.news = val;
  },

  markAllNewsRead(state) {
    state.news = state.news.map(news => {
      news.read = true;
      return news;
    });
    state.unreadNewsCount = 0;
  },

  getUnreadNewsCount(state, val) {
    state.unreadNewsCount = state.news.filter((item)=>{
      return !$history.hasRead("news." + item.id, item.created);
    }).length;
  },

  updateUnreadNewsCount(state, val) {
    state.unreadNewsCount = val;
  },

  // Testimonies
  setTestimonies(state, val) {
    state.testimonies = val;
  },

  markAllTestimoniesRead(state) {
    state.testimonies = state.testimonies.map(news => {
      testimonies.read = true;
      return testimonies;
    });
    state.unreadTestimoniesCount = 0;
  },

  getUnreadTestimoniesCount(state, val) {
    state.unreadTestimoniesCount = state.testimonies.filter((item)=>{
      return !$history.hasRead("testimony." + item.id, item.created);
    }).length;
  },

  updateUnreadTestimoniesCount(state, val) {
    state.unreadTestimoniesCount = val;
  },

  updateTheme(state, val) {
    state.theme = val;
    console.log(state);
  },

  updateFontSize(state, val) {
    state.fontsize = val;
  },

  updateFontFamily(state, val) {
    state.fontfamily = val;
  },

}
