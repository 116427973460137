export default {
  markRead(key) {
    localStorage['read.' + key] = 1;
  },
  hasRead(key, created) {
    const createdTime = new Date(created).getTime();
    const output = !!localStorage['read.' + key] || ((Date.now() - createdTime) > (1000 * 60 * 60 * 24 * 30));
    return output;
  }
}
