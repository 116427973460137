<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>THE VISION</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">{{ translate.labels["app.branding.onelord"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.onechurch"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.oneword"] }}</div>
    </div>
    <h3>THE MISSION</h3>
    <ol>
      <li>Expression of Unity as all believers listen to God’s Word together</li>
      <li>Life transformation and revival </li>
      <li>Discipleship through habit of daily Bible listening </li>
      <li>Outreach to pre-believers </li>
      <li>Accomplish Vision 2033: 99% of people in the world could hear the Bible in their own heart language.</li>
    </ol>
    <p>
      <span>Together we will accomplish Vision 2033: </span>
      <a @click="openLink('https://www.faithcomesbyhearing.com/about/our-mission')">https://www.faithcomesbyhearing.com/about/our-mission</a>
      <span>that is to record the Word of God in every heart language of the world that needs it by 2033 and make them freely available!</span>
     </p>
     <h3>WHO WE ARE</h3>
     <p>Birthed by the one little mustard seed in 2019, ONE Hearing the Word Campaign (ONE Campaign) was co-founded by Faith Comes By Hearing Asia (FCBH Asia) and Hong Kong Evangelical Fellowship (HKEF).  The ONE Campaign, a bible listening campaign rallying hundreds of churches in Hong Kong during the 47-day Lent to express the unity of believers under the Word of God, aims to demonstrate that Christians are ONE Church and that there is only One Body of Christ. </p>
     <p>As we listen and meditate on the Word of God together, hearts will be touched, spirits ignited, and there will be a revival and transformation of lives, for we shall be reminded of our call to be witnesses to the ends of the earth.  </p>
     <p>Inspired by the Vision of ONE Campaign, both Rev. Dr. Randy Frazee, Senior Pastor of Westside Family Church and Dr. Daniel KL Chua, Chair Professor of Music at The University of Hong Kong responded to the Call in the year 2021 by rallying international cities to participate in ONE Campaign, and composed a theme song for ONE Campaign (called “ONE Song”) respectively.  Glory to be God, ONE Campaign has now become a global movement!</p>
     <p>In 2021, 32 cities from around the world joined ONE Campaign including those from Asian countries - Singapore, Malaysia, Vietnam and Ghana from Africa.</p>
     <p>The ONE Song references Scripture closely, unfolding the epic journey from the Great Commission in Matthew 28 to New Jerusalem in Revelations 22, to express the unity of God and his Church, and how that unity is the driving force for the vision and mission of God’s Kingdom.</p>
     <p>With the concerted efforts of the Faith Comes By Hearing regional team, three more languages (Malay, Tamil and Vietnamese) will be added to the “ONE Hearing the Word” (ONE) app from the year 2023.</p>
     <iframe src="https://iframe.mediadelivery.net/embed/100722/660bd19a-258c-4174-9e8c-419a2d2f509b"></iframe>

     <!-- <h3>THE STORY OF "5"</h3>
     <p>Praise God for His perfect timing in the provision of a HK$5 Million matching fund right before we launched the ONE Hearing the Word Campaign in 2021 on Feb 17th. We believe there is a message for our ONE Campaign participants: Do you feel blessed as you listen to God’s Word?  Would you like to multiply this blessing and let His Word reach the last, the least and the forgotten peoples?</p>
     <p>If 400 of you respond to this matching challenge, give HKD500+ monthly for 5 months and bring 5+ friends to give, thousands upon thousands of peoples in Asia will be blessed by you and will be able to hear and be transformed by God’s Word for the first time in their lives.  This would make us one step closer to accomplishing the Vision 2033.</p>
     <iframe src="https://youtube.com/embed/uI7x2C58bq0"></iframe> -->

     <h3>3 WAYS YOU CAN HELP</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>Share</h4>
       <p>Share this video with friends and help spread the word on social media.</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>Pray</h4>
       <p>Join us in praying that the work begun by God towards Vision 2033 will be fulfilled in this generation.</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>Give</h4>
       <p>Give generously and joyfully and be a part of changing the world for Christ.</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">Give</ion-button>

     </div>

     <h3>More Info</h3>
     <p>To find out more about ONE Campaign, please visit our website:  www.onehtw.com, contact us via email: hello@faithcomesbyhearing.asia.</p>

     <h4>Faith Comes By Hearing (FCBH)</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>FCBH Asia is part of the family of FCBH in Albuquerque, U.S.A. which is an international audio Bible recording ministry and has audio Bibles available in over 1,700 languages. For 50 years, it has been committed to providing everyone, especially the poor and the illiterate with free access to the pure Word of God in their own heart language. From cutting-edge online and mobile technologies to solar-powered devices, its resources are currently used in almost every country in the world. Through its popular bible App, BIBLE.IS, it is committed and will make available the entire updated recordings of God’s Word in every language recorded. It is one of the missions of FCBH to realize Vision 2033, which aims to have the Word of God made available for 99.9% of the world’s population so that every tongue, and every tribe can know that Jesus is Lord. FCBH is also a top-rated charity with Charity Navigator for over 10 consecutive years.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
