export default {
  news: {},
  unreadNewsCount: 0,
  testimonies: {},
  unreadTestimoniesCount: 0,
  launchApp: false,
  theme: localStorage.theme || 'default',
  fontsize: localStorage.fontsize || 'default',
  fontfamily: localStorage.fontfamily || 'default',
}
