<template>
  <div class="">
    <h3>PARTNERS</h3>
    <ion-item v-for="(partner, index) in displayPartners" :key="index">
      <ion-label @click="openLink(partner.website)">
        <div>{{ partner.name || partner.name_eng }}</div>
        <div v-if="partner.name_local">{{ partner.name_local }}</div>
      </ion-label>
    </ion-item>
  </div>
</template>

<script>
import $api from '@/services/api';
import $analytics from '@/services/analytics';

export default {
  data() {
    return {
      partners: null,
    };
  },
  computed: {
    displayPartners() {
      let output = this.partners && this.partners.filter((p) => {
        return (!localStorage.country || (p.country == localStorage.country));
      }).sort((a, b) => {
        return (a.name < b.name) ? -1 : 1;
      });
      return output;
    },
  },
  methods: {
    async refreshData() {
      this.partners = await $api.get('/partners');
    },
    openLink(url){
      // !!window.cordova ? cordova.InAppBrowser.open(encodeURI(url), '_system') : window.open(url,"_blank");
      this.$root.$emit('openurl', url);
      $analytics.track("about-page-link",url);
    }
  },
  mounted() {
    this.refreshData();
  }
};
</script>

<style lang="css" scoped>
</style>
