import $api from '@/services/api';

localStorage.userid = localStorage.userid || 'user.' + Math.floor(Math.random() * 1000000000);
localStorage.created = localStorage.created || Date.now();

const $user = {};
$user.id = localStorage.userid;
$user.token = null;

$user.save = function() {
  if($user.token){
    $api.post('/api/users', {
      _id: $user.token,
      church: localStorage.church,
      language: localStorage.language,
      version: '1.6',
      userid: localStorage.userid,
      created: localStorage.created,
      updated: Date.now(),
    });
  }
};

export default $user;
