<template>
  <div>
    <i class="icons8-play" @click="playAudio()" v-if="!playing"></i>
    <i class="icons8-pause" @click="pauseAudio()" v-if="playing"></i>
  </div>
</template>
<script>
  import $analytics from "@/services/analytics";
  import $audio from "@/services/audio";

  export default {
    props:["src","start","end","title","analyticsId","analyticsAction"],
    data:function(){
      return {
        playing:false
      }
    },
    methods:{
      playAudio:function(){
        var item = {
          src: this.src,
          start: this.start,
          end: this.end,
          title: this.title || "Example Title",
          analyticsId: this.analyticsId,
          analyticsAction: this.analyticsAction
        }
        if($audio.currentTrack && (this.src == $audio.currentTrack.src)){
          // Already on this track
          $audio.resume();
        }
        else{
          // Not on this track
          $audio.cue(item);
        }
      },
      pauseAudio:function(){
        $audio.pause();
      },
      handlePlayStateEvent(){
        this.playing = $audio.currentTrack && (this.src == $audio.currentTrack.src) && $audio.isPlaying();
      }
    },
    created:function(){
      this.handlePlayStateEvent();
      $audio.subscribe((e)=>{
        this.handlePlayStateEvent();
      });
      // Attempt to preload the file
      if(this.src && window.fetch){
        //fetch($scope.src,{mode:"no-cors"});
      }
    }
  }
</script>
<!--
<div class="audio-item">
</div>
app.directive("audioItem",function($audio){
    controller: function ($scope,$audio,$element) {
      $element[0].getAudioItem = function(){
        return {
          src: $scope.src,
          start: $scope.start,
          end: $scope.end,
          title: $scope.title,
          analyticsid: $scope.analyticsid,
          analyticsaction: $scope.analyticsaction
        }
      }
    }
  }
})
-->
<style scoped>
  .audio-item{
    user-select: none !important;
    -webkit-user-select: none !important;
    display: inline-block;
    height: 40px;
    width: 40px;
  }
  .audio-item .buttons-holder{
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .audio-item .buttons-holder .icon{
    font-size:24px;
    color: #FFF;
    display: block;
    height: 100%;
    width: 100%;
    padding: 8px;
    border-radius: 50%;
    background-color: #888;
    cursor: pointer;
  }
  .audio-item .buttons-holder .ion-ios-play {
    padding-left: 10px;
    padding-right: 6px;
  }
  i {
    color: var(--ion-color-primary);
    font-size: 24px;
  }
</style>
