<template>
    <div class="offline" v-show="!networkStatus">
        <img src="@/assets/wifi.png" alt="">
        <h1>No connection</h1>
        <p>Please check your internet connection.</p>
        <ion-button @click="refresh" type="submit" fill="solid" shape="round">
            Try Again
        </ion-button>
    </div>
</template>

<script>
export default {
    name: 'offline',
    
    data() {
      return {
        networkStatus: false,
      }
    },

  mounted() {
    navigator.onLine ? this.showStatus(true) : this.showStatus(false);

    window.addEventListener('online', () => {
      this.showStatus(true);
    });

    window.addEventListener('offline', () => {
      this.showStatus(false);
    });
  },

  methods: {
    refresh() {
      window.location.reload();
    },

    showStatus(online) {
      if (online) {
        this.networkStatus = true;
        if (!this.$store.state.global.lauchApp) {
          const loadData = this.$store.dispatch('translations/getLabels');
          loadData.then(() => {
             this.$store.commit('global/setLaunchAppStatus', true)
          });
         
        }
      } else {
        this.networkStatus = false;
      }
    }
  },
}

</script>
<style scoped>
  .offline {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.76);
    color: white;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .offline img {
    width: 105px;
  }

  .offline p {
    margin: 0 0 10px;
    font-size: 18px;
  }

  .offline ion-button {
    margin-top: 15px;
  }
</style>