<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="share()">{{translate.labels['app.general.share']}}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <AppDevotional :date="date" @loaded="onDevotionalLoaded"></AppDevotional>
    </ion-content>
  </ion-page>
</template>
<script>
  import $analytics from '@/services/analytics';
  import AppDevotional from "@/components/AppDevotional";
  import { mapState } from 'vuex';

  export default {
    components:{
      AppDevotional
    },
    computed: {
      ...mapState('translations', {
          translate: 'translate',
      }),
    },
    data:function(){
      return {
        title:null,
        date:null
      }
    },
    methods:{
      onDevotionalLoaded(devotional){
        this.title = devotional.title;
      },
      share() {
        const url = `${process.env.VUE_APP_PREVIEW_ENDPOINT}/devotional/${localStorage.language}.${this.$route.params.date}`;
        if(!window.plugins || !window.plugins.socialsharing){
          console.log('Share', url);
        }
        else{
          window.plugins.socialsharing.shareWithOptions({url:url}, console.log, console.log);
        }
        $analytics.track('share-devotional');
      },
    },
    created:function(){
      this.date = this.$route.params.date;
    }
  }
</script>
