<template>
<div>
  <app-spinner v-if="!devotional"></app-spinner>
  <div v-if="devotional">

    <!-- PLAY ALL -->
    <div class="ion-padding" v-if="!devotional.gospel_film_videos">
      <ion-button expand="block" v-if="playlist" @click="playAll()">
        <i class="icons8-play" name="play"></i>
        {{ translate.labels["app.content.playAllButton"] }}
      </ion-button>
    </div>

    <!-- SPECIAL PRAYER -->
    <ion-list v-if="devotional.special_prayer_text">
      <ion-list-header>
        <ion-label>
          <h3>{{translate.labels["app.content.specialPrayerTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <ion-label class="one-text-wrap">
          {{translate.labels["app.content.specialPrayerHeader"] }}
        </ion-label>
      </ion-item>
      <ion-item lines="none" v-if="devotional.bible_quote">
        <ion-label class="one-text-wrap">
          <!-- BIBLE QUOTE -->
          {{devotional.bible_quote}}
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <div class="align-top">
          <AudioItem slot="start" :title="translate.labels['app.content.specialPrayerTitle'] " :src="devotional.special_prayer_audio" analytics-action="specialprayer-audio-play" :analytics-id="devotional.date"></AudioItem>
        </div>
        <ion-label class="one-text-wrap">
          <div style="white-space:pre-wrap">{{devotional.special_prayer_text}}</div>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- OPENING PRAYER -->
    <ion-list v-if="devotional.opening_prayer_text">
      <ion-list-header>
        <ion-label>
          <h3>{{translate.labels["app.content.openingPrayerTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <div class="align-top">
          <AudioItem slot="start" :title="translate.labels['app.content.openingPrayerTitle'] " :src="devotional.opening_prayer_audio" analytics-action="openingprayer-audio-play" :analytics-id="devotional.date"></AudioItem>
        </div>
        <ion-label class="one-text-wrap">
          <div style="white-space:pre-wrap">{{devotional.opening_prayer_text}}</div>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- READINGS -->
    <ion-list v-if="devotional.readings && devotional.readings.length > 0">
      <ion-list-header>
        <ion-label>
          <h3>{{ translate.labels["app.content.readingsTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item v-for="(reading,index) in devotional.readings" :key="index" lines="none">
        <AudioItem v-if="reading.audio" slot="start" :title="reading.display_passage" :src="reading.audio" :start="reading.start" :end="reading.end" analytics-action="bible-audio-play" :analytics-id="reading.passage"></AudioItem>
        <ion-label class="one-text-wrap">
          <div v-router-link="{name:'reading',params:{id:reading.passage},query:{name:reading.display_passage}}">{{reading.display_passage}}</div>
        </ion-label>
      </ion-item>
    </ion-list>


    <!-- TESTIMONY -->
    <ion-list v-if="devotional.video">
      <ion-list-header>
        <ion-label>
          <h3>{{ translate.labels["app.content.videoTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <YoutubePlayer :src="devotional.video" analytics-action="testimony-video-play" :analytics-id="devotional.date"></YoutubePlayer>
      </ion-item>
      <ion-item lines="none">
        <AudioItem slot="start" :title="translate.labels['app.content.videoTitle'] " :src="devotional.testimony_audio" analytics-action="testimony-audio-play" :analytics-id="devotional.date"></AudioItem>
        <ion-label class="one-text-wrap">
          <div>{{devotional.testimony_text}}</div>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- DEVOTIONAL -->
    <ion-list v-if="devotional.devotional_text">
      <ion-list-header>
        <ion-label>
          <h3>{{translate.labels["app.content.devotionalTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <div class="align-top">
          <AudioItem slot="start" v-if="devotional.devotional_audio" :title="translate.labels['app.content.devotionalTitle']" :src="devotional.devotional_audio" analytics-action="devotional-audio-play" :analytics-id="devotional.date">
          </AudioItem>
        </div>
        <ion-label class="one-text-wrap">
          <div>{{devotional.devotional_text}}</div>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- PRAYER -->
    <ion-list v-if="devotional.prayer_text">
      <ion-list-header>
        <ion-label>
          <h3>{{ translate.labels["app.content.prayerTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <div class="align-top">
          <AudioItem slot="start" v-if="devotional.prayer_audio" :title="translate.labels['app.content.prayerTitle']" :src="devotional.prayer_audio" analytics-action="prayer-audio-play" :analytics-id="devotional.date"></AudioItem>
        </div>
        <ion-label class="one-text-wrap">
          <p>{{devotional.prayer_text}}</p>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- GOSPEL FILM VERSION 2 (2022+) -->
    <div v-if="devotional.gospel_film_videos">
      <ion-list v-for="gfilm of devotional.gospel_film_videos" :key="gfilm">
        <ion-item lines="none">
          <!-- <YoutubePlayer :src="" analytics-action="gospelfilm-video-play" :analytics-id="devotional.date"></YoutubePlayer> -->
          <iframe :src="gfilm.url"></iframe>
        </ion-item>
      </ion-list>
    </div>

    <!-- CLOSING PRAYER -->
    <ion-list v-if="devotional.closing_prayer_text">
      <ion-list-header>
        <ion-label>
          <h3>{{ translate.labels["app.content.closingPrayerTitle"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <div class="align-top">
          <AudioItem slot="start" :title="translate.labels['app.content.closingPrayerTitle'] " :src="devotional.closing_prayer_audio" analytics-action="closingprayer-audio-play" :analytics-id="devotional.date"></AudioItem>
        </div>
        <ion-label class="one-text-wrap">
          <div>{{devotional.closing_prayer_text}}</div>
        </ion-label>
      </ion-item>
    </ion-list>


    <!-- DAILY CLIPS -->
    <ion-list v-if="devotional.videos && devotional.videos.length > 0">
      <ion-list-header>
        <ion-label>
          <h3>{{ translate.labels["app.films.dailyFilm"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <VideoPlaylist :files="devotional.videos" analytics-action="daily-film-play" :analytics-id="devotional.date"></VideoPlaylist>
      </ion-item>
    </ion-list>

    <!-- WEEKLY CLIPS -->
    <ion-list v-if="devotional.big_videos && devotional.big_videos.length > 0">
      <ion-list-header>
        <ion-label>
          <h3>{{ translate.labels["app.films.weeklyFilm"] }}</h3>
        </ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <VideoPlaylist :files="devotional.big_videos" analytics-action="weekly-film-play" :analytics-id="devotional.date"></VideoPlaylist>
      </ion-item>
    </ion-list>


    <!-- GOSPEL FILM -->
    <ion-list v-if="devotional.gospel_film_video">
      <ion-item lines="none">
        <!-- <YoutubePlayer :src="" analytics-action="gospelfilm-video-play" :analytics-id="devotional.date"></YoutubePlayer> -->
        <iframe :src="devotional.gospel_film_video"></iframe>
      </ion-item>
    </ion-list>

  </div>
</div>
</template>

<script>
import $api from "@/services/api";
import $audio from "@/services/audio";
import $analytics from "@/services/analytics";
import $history from "@/services/history";

import AudioItem from "@/components/AudioItem";
import VideoPlaylist from "@/components/VideoPlaylist";
import YoutubePlayer from "@/components/YoutubePlayer"
import AppSpinner from "@/components/AppSpinner";
import {
  mapState
} from 'vuex';

export default {
  components: {
    AudioItem,
    VideoPlaylist,
    YoutubePlayer,
    AppSpinner
  },
  props: ["date"],
  data: function() {
    return {
      devotional: null,
      playlist: null
    }
  },
  computed: {
    ...mapState('translations', {
      translate: 'translate',
    }),
  },
  methods: {
    playAll: function() {
      if (this.playlist) {
        $audio.cue(this.playlist);
      }
    },
    refreshData: function() {
      $analytics.track("daily-devo", this.date);
      $history.markRead("devo." + this.date);
      $api.get("/devotionals/" + localStorage.language + "." + this.date).then(devotional => {
        this.devotional = devotional;
        this.$emit("loaded", devotional);

        var playlist = [];
        if (this.devotional.special_prayer_audio) {
          playlist.push({
            title: this.translate.labels['app.content.specialPrayerTitle'],
            src: this.devotional.special_prayer_audio,
            analyticsAction: "specialprayer-audio-play",
            analyticsId: devotional.date
          })
        }
        if (this.devotional.opening_prayer_audio) {
          playlist.push({
            title: this.translate.labels['app.content.openingPrayerTitle'],
            src: this.devotional.opening_prayer_audio,
            analyticsAction: "openingprayer-audio-play",
            analyticsId: devotional.date
          })
        }
        if(this.devotional.readings) {
          this.devotional.readings = this.devotional.readings.filter((r) => (r.passage.includes(':')));
          this.devotional.readings.forEach(function(reading) {
            if (devotional.campaign < 30 && localStorage.language == "KOR") {
              // No audio for Korean campaigns before 30
            } else {
              reading.audio = reading.audio || 'https://api.onehtw.com/readings/audio/' + reading.passage + "?language=" + localStorage.language;
            }
            playlist.push({
              title: reading.display_passage,
              src: reading.audio,
              start: reading.start,
              end: reading.end,
              analyticsId: reading.passage,
              analyticsAction: "bible-audio-play"
            });
          })
        }
        if (this.devotional.testimony_audio) {
          playlist.push({
            title: this.translate.labels['app.content.videoTitle'],
            src: this.devotional.devotional_audio,
            analyticsId: this.devotional.date,
            analyticsAction: "testimony-audio-play"
          });
        }
        if (this.devotional.devotional_audio) {
          playlist.push({
            title: this.translate.labels['app.content.devotionalTitle'],
            src: this.devotional.devotional_audio,
            analyticsAction: "devotional-audio-play",
            analyticsId: devotional.date
          });
        }
        if (this.devotional.prayer_audio) {
          playlist.push({
            title: this.translate.labels['app.content.prayerTitle'],
            src: this.devotional.prayer_audio,
            analyticsAction: "prayer-audio-play",
            analyticsId: devotional.date
          });
        }
        if (this.devotional.closing_prayer_audio) {
          playlist.push({
            title: this.translate.labels['app.content.closingPrayerTitle'],
            src: this.devotional.closing_prayer_audio,
            analyticsAction: "closingprayer-audio-play",
            analyticsId: devotional.date
          })
        }
        if (playlist.length > 0) {
          this.playlist = playlist;
        }
      })
    }
  },
  watch: {
    date() {
      this.refreshData();
    }
  },
  created: function() {
    this.refreshData();
  }
}
</script>

<style scoped>
.align-top {
  height: 100%;
  padding: 15px 15px 0 0;
}

one-text-wrap {
  line-height: 1.5;
}

i {
  margin-right: 8px;
}
iframe
{
  width: 100%;
  height: 200px;
}
</style>
