<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ translate.labels['app.settings.country'] }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label class="one-text-wrap">
          <p>{{ translate.labels['app.welcome.introCountry'] }}</p>
        </ion-label>
      </ion-item>
      <country-selector @change="next()" />
    </ion-content>
  </ion-page>
</template>
<script>
import $api from '@/services/api';
import $analytics from '@/services/analytics';
import AppSpinner from '@/components/AppSpinner';
import CountrySelector from '@/components/CountrySelector';
import { mapState } from 'vuex';

export default {
  components: {
    CountrySelector,
  },
  computed: {
    ...mapState('translations', {
       translate: 'translate',
    }),
  },
  methods:{
    next(){
      this.$router.push({
        name: 'onboarding-church'
      });
    }
  },
  created:function(){
    $analytics.track('country-settings-view');
  },
};
</script>
