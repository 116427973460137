<template>
  <div>
    <app-spinner v-if="!countries"></app-spinner>
    <ion-searchbar
      v-if="countries"
      @input="query = $event.target.value"
      :placeholder="translate.labels['app.general.searchPlaceholder']"
    ></ion-searchbar>
    <ion-list v-if="countries">
      <ion-item v-for="country in countries" :key="country.id">
        <ion-label>{{country.name}}</ion-label>
        <ion-radio slot="end" @ionSelect="setCountry(country)" :checked="(selectedCountry == country.id)"></ion-radio>
      </ion-item>
    </ion-list>
  </div>
</template>
<script>
import countries from '@/assets/countries.json';
import { mapState } from 'vuex';

const priority = [
  'Hong Kong',
  'Singapore',
  'Malaysia',
  'Vietnam',
]

export default {
  data() {
    return {
      query: '',
      selectedCountry: null,
    }
  },
  computed: {
    ...mapState('translations', {
      translate: 'translate',
    }),
    countries() {
      return countries.map((c) => {
        return {
          name: `${c.emoji} ${c.name}`,
          id: c.name,
          priority: priority.includes(c.name) ? 1 : 0,
        };
      }).sort((a, b) => {
        return b.priority - a.priority;
      }).filter((c) => (c.name.toLowerCase().includes(this.query.toLowerCase())));
    },
  },
  methods: {
    setCountry(country) {
      localStorage.country = country.id;
      this.$emit('change');
    },
  },
};
</script>
