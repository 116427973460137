<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>VISI</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">SATU Tuhan</div>
      <div class="brand-words">SATU Gereja</div>
      <div class="brand-words">SATU Firman</div>
    </div>
    <h3>MISI</h3>
    <ol>
      <li>Ekspresi Kesatuan karena semua orang percaya mendengarkan Firman Tuhan bersama-sama</li>
      <li>Transformasi dan kebangkitan kehidupan</li>
      <li>Pemuridan melalui kebiasaan mendengarkan Firman Tuhan setiap hari</li>
      <li>Penjangkauan orang-orang yang belum percaya</li>
      <li>Pencapaian Visi 2033: 99% orang di dunia dapat mendengar Firman Tuhan dalam bahasa ibu mereka sendiri.</li>
    </ol>
    <p>
      <span>Bersama-sama kita akan mencapai Visi 2033: </span>
      <a @click="openLink('https://www.faithcomesbyhearing.com/about/our-mission')">https://www.faithcomesbyhearing.com/about/our-mission</a>
      <span>yaitu untuk merekam Firman Tuhan dalam setiap bahasa ibu di dunia yang membutuhkannya pada tahun 2033 dan membuatnya tersedia secara cuma-cuma!</span>
     </p>
     <h3>SIAPA KAMI</h3>
     <p>Lahir dari benih sesawi kecil pada tahun 2019, ONE Hearing the Word Campaign (ONE Campaign) didirikan bersama oleh Faith Comes By Hearing Asia (FCBH Asia) dan eks Hong Kong Evangelical Fellowship (HKEF). The ONE Campaign, sebuah kampanye mendengarkan Firman Tuhan yang menggalang ratusan gereja di Hong Kong selama 47 hari pada masa  Prapaskah untuk mengungkapkan kesatuan umat beriman di bawah Firman Allah, bertujuan untuk menunjukkan bahwa umat Kristiani adalah SATU Gereja dan bahwa hanya ada Satu Tubuh Kristus.</p>
     <p>Saat kita mendengarkan dan merenungkan Firman Tuhan bersama-sama, hati akan dijamah, roh dinyalakan, dan akan ada kebangkitan dan transformasi kehidupan, karena kita akan diingatkan terhadap panggilan kita untuk menjadi saksi sampai ke ujung bumi.</p>
     <p>Terinspirasi oleh Visi dari ONE Campaign, baik Pdt. Dr. Randy Frazee, Pendeta Senior dari Westside Family Church dan Dr. Daniel KL Chua, Ketua Profesor bagian Musik di  University of Hong Kong menanggapi Panggilan pada tahun 2021 dengan menggalang kota-kota internasional  untuk berpartisipasi dalam ONE Campaign, dan membuat lagu tema untuk ONE Campaign (disebut “ONE Song”). Dimuliakanlah  Tuhan, ONE Campaign kini telah menjadi gerakan global!</p>
     <p>Pada tahun 2021, 32 kota dari seluruh dunia bergabung dalam ONE Campaign termasuk dari negara-negara Asia - Singapura, Malaysia, Vietnam dan Ghana dari Afrika.</p>
     <p>Lagu ONE Song merujuk ayat Firman Tuhan dengan cermat, membentangkan perjalanan epik dari Amanat Agung dalam Matius 28 ke Yerusalem Baru dalam Wahyu 22, untuk mengungkapkan kesatuan Allah dan Gereja-Nya, dan bagaimana kesatuan itu menjadi kekuatan pendorong bagi visi dan misi Kerajaan Allah.</p>
     <p>Dengan upaya bersama dari tim regional Faith Comes By Hearing, tiga bahasa lagi (Melayu, Tamil, dan Vietnam) akan ditambahkan ke aplikasi “ONE Hearing the Word” (ONE) mulai tahun 2023.</p>
     <iframe src="https://iframe.mediadelivery.net/embed/100722/64307425-8d94-44f1-8814-de9059a282d1"></iframe>

     <h3>3 Cara Anda Dapat Membantu</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>Membagikan</h4>
       <p>Bagikan visi ini dengan teman-teman dan bantu sebarkan berita ini di media sosial.</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>Berdoa</h4>
       <p>Bergabunglah dengan kami dalam doa agar pekerjaan dimulai oleh Tuhan menuju Visi 2033 akan terpenuhi pada generasi ini.</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>Memberi</h4>
       <p>Memberi dengan murah hati dan penuh sukacita dan menjadi bagian dari mengubahkan dunia bagi Kristus.</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">Memberi</ion-button>

     </div>

     <h3>Info Lebih Lanjut</h3>
     <p>Untuk mengetahui lebih lanjut tentang ONE Campaign, silakan kunjungi situs web kami: www.onehtw.com, hubungi kami melalui email: hello@faithcomesbyhearing.asia.</p>

     <h4>Faith Comes By Hearing (FCBH)</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>FCBH Asia adalah bagian dari keluarga FCBH di Albuquerque, AS yang merupakan pelayanan rekaman Alkitab audio internasional dan memiliki Alkitab audio yang tersedia dalam lebih dari 1.700 bahasa. Selama 50 tahun, telah berkomitmen untuk menyediakan bagi setiap orang, terutama orang kurang mampu dan buta huruf dengan akses cuma-cuma ke Firman Tuhan yang murni dalam bahasa ibu mereka sendiri. Dari teknologi online dan seluler mutakhir hingga perangkat bertenaga surya, sumber dayanya saat ini digunakan di hampir setiap negara di dunia. Melalui Aplikasi Alkitabnya yang populer, BIBLE.IS, telah berkomitmen dan akan menyediakan seluruh rekaman Firman Tuhan yang diperbarui dalam setiap bahasa yang direkam. Salah satu misi FCBH adalah mewujudkan Visi 2033 yang bertujuan agar Firman Tuhan tersedia bagi 99,9% penduduk dunia sehingga setiap bahasa, dan setiap suku dapat mengetahui bahwa Yesus adalah Tuhan. FCBH juga merupakan badan amal peringkat teratas dengan Charity Navigator selama lebih dari 10 tahun berturut-turut.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
