<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ translate.labels["app.settings.about"] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <about-yuh v-if="displayVersion == 'YUH'" @open="openLink" />
      <about-eng v-if="displayVersion == 'ENG'" @open="openLink" />
      <about-chn v-if="displayVersion == 'CHN'" @open="openLink" />
      <about-ind v-if="displayVersion == 'IND'" @open="openLink" />
      <about-kor v-if="displayVersion == 'KOR'" @open="openLink" />
      <about-tgl v-if="displayVersion == 'TGL'" @open="openLink" />
      <about-may v-if="displayVersion == 'MAY'" @open="openLink" />
      <about-tam v-if="displayVersion == 'TAM'" @open="openLink" />
      <about-vie v-if="displayVersion == 'VIE'" @open="openLink" />
      <partners-list />
    </ion-content>
  </ion-page>
</template>
<script>
import AboutChn from '@/components/about/AboutChn.vue';
import AboutEng from '@/components/about/AboutEng.vue';
import AboutYuh from '@/components/about/AboutYuh.vue';
import AboutInd from '@/components/about/AboutInd.vue';
import AboutKor from '@/components/about/AboutKor.vue';
import AboutTgl from '@/components/about/AboutTgl.vue';
import AboutMay from '@/components/about/AboutMay.vue';
import AboutTam from '@/components/about/AboutTam.vue';
import AboutVie from '@/components/about/AboutVie.vue';
import PartnersList from '@/components/PartnersList.vue';

import $api from "@/services/api";
import $analytics from "@/services/analytics";
import { mapState } from 'vuex';

export default {
  components: {
    AboutChn,
    AboutEng,
    AboutYuh,
    AboutInd,
    AboutKor,
    AboutTgl,
    AboutMay,
    AboutTam,
    AboutVie,
    PartnersList,
  },
  data (){
    return {
      partners:null,
      countdownVideo: null,
    }
  },
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
    displayVersion() {
      return localStorage.language || 'ENG';
    },
  },
  methods:{
    openLink(url) {
      console.log('Open Link', url);
      this.$root.$emit('openurl', url);
      $analytics.track("about-page-link",url);
    }
  },
  created(){
    $analytics.track("about-page-view");
  }
}
</script>
<style>
  .about-us .item-content .html-link {
    color: #387ef5;
    text-decoration: underline;
    cursor: pointer;
  }
  .respect-lines
  {
    white-space: pre-line !important;
  }
  iframe
  {
    width: 100%;
    height: 200px;
  }
  .brand-header {
      background-image: url('../../assets/bg2021.png');
      background-size: cover;
      padding: 20px;
      text-align: center;
      color: white;
  }

  .brand-header img {
      width: 80px;
  }
  .action-steps
  {
    text-align: center;
  }
  .action-icon
  {
    width: 50px;
  }
</style>
