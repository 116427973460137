<template>
<ion-page>
  <ion-header>
    <ion-toolbar>
      <ion-title>
          {{ title }}
          <!-- translate.labels["app.countdown.title"]-->
      </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="share()">{{translate.labels['app.general.share']}}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">{{ translate.labels["app.branding.onelord"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.oneword"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.onechurch"] }}</div>
    </div>
    <!-- <div class="home-card">
      <YoutubePlayer :src="countdownVideo"></YoutubePlayer>
    </div>
    <div class="home-card">
      <h1>{{ countdownTitle }}</h1>
      <div class="text-center text-padding one-text-wrap">{{ translate.labels["app.countdown.description"] }}</div>
      <div class="text-padding">
        <ion-button expand="" fill="clear" @click="browse()" side="start">{{ translate.labels["app.countdown.explore"] }}</ion-button>
        <ion-button expand="block" @click="invite()" side="end">{{ translate.labels["app.countdown.invite"] }}</ion-button>
      </div>
    </div> -->
    <div class="home-card">
      <h1>{{ today | moment("MMM Do") }}</h1>
      <AppDevotional :date="today" @loaded="onDevotionalLoaded"></AppDevotional>
    </div>
    <!-- <div class="text-padding">
      <ion-button expand="block" @click="donate()" side="start">{{ translate.labels['app.general.donateButton']}} </ion-button>
    </div> -->
    <div class="home-card">
      <h1>{{ translate.labels["app.news.title"] }}</h1>
      <AppNews :limit="3"></AppNews>
    </div>
  </ion-content>
</ion-page>
</template>
<script>
import $analytics from '@/services/analytics';
import YoutubePlayer from '@/components/YoutubePlayer';
import { mapState } from 'vuex';
import AppDevotional from '@/components/AppDevotional';
import AppNews from '@/components/AppNews';

function getLocalDate() {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  return localISOTime.split('T')[0];
}

export default {
  name: 'home',
  components: {
    AppDevotional,
    YoutubePlayer,
    AppNews,
  },
  data() {
    return {
      language: localStorage.language,
      today: getLocalDate(),
      title: '',
    };
  },
  created() {
    $analytics.track('home-page-view');
    this.refresh();
    document.addEventListener('resume', () => {
      this.refresh();
    });
    setInterval(()=>{
      const currentDate = getLocalDate();
      if (currentDate != this.today) {
        this.refresh();
      }
    }, 1000);
  },
  computed: {
    ...mapState('translations', {
      translate: 'translate',
    }),

    countdownTitle() {
      const campaignStartDate = new Date(2023, 1, 22).getTime();
      const remainingDays = Math.ceil((campaignStartDate - Date.now()) / (1000 * 60 * 60 * 24));
      return this.translate.labels['app.countdown.daysRemaining'].replace('#', remainingDays).replace('＃', remainingDays);
    },

    countdownVideo() {
      return `https://www.youtube.com/embed/5-dhlm8b6aE`
    }
  },
  methods: {
    refresh() {
      this.today = getLocalDate();

      const momentMap = {
        ENG: 'en',
        TGL: 'tl-ph',
        KOR: 'ko',
        IND: 'id',
        YUH: 'zh-tw',
        CHN: 'zh-cn',
        VIE: 'vi',
        MAY: 'ms',
        TAM: 'en',
      }
      const language = localStorage.language || 'ENG';
      const momentId = momentMap[language];
      this.$moment.locale(momentId);
    },
    onDevotionalLoaded(devotional) {
      this.title = devotional.title;
    },
    browse() {
      this.$router.push({
        name: 'campaign-list',
      });
    },
    invite() {
      $analytics.track('invite-friends');
      if (!window.cordova) {
        alert('This feature only works on installed apps.');
      } else {
        const shareLink = 'https://go.onehtw.com';
        const message = this.translate.labels['app.countdown.invitationText'];
        window.plugins.socialsharing.shareWithOptions({
          message: message,
          url: shareLink
        }, console.log, console.log);
      }
    },
    share() {
      const url = `${process.env.VUE_APP_PREVIEW_ENDPOINT}/devotional/${localStorage.language}.${this.today}`;
      if (!window.plugins || !window.plugins.socialsharing) {
        console.log('Share', url);
      }
      else{
        window.plugins.socialsharing.shareWithOptions({url:url}, console.log, console.log);
      }
      $analytics.track('share-devotional');
    },
    donate() {
      const url = 'https://faithcomesbyhearing.asia/donate';
      this.$root.$emit('openurl', url);
    },
  }
}
</script>
<style scoped>
.brand-header {
  background-image: url('../assets/bg2021.png');
  background-size: cover;
  padding: 20px;
  text-align: center;
  color: white;
}

.brand-header img {
  width: 80px;
}
.home-card h1 {
  padding-left: 15px;
}
.text-padding {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
