import $analytics from '@/services/analytics';

// The audio element used to actually perform playback
var _audio = new Audio();
_audio.autoplay = true;
window._audio = _audio;

var listeners = [];
function notifyListeners(){
  listeners.forEach(function(listener){
    listener && listener();
  })
}

_audio.addEventListener('play',function(){
  $audio.state = 'playing';
  notifyListeners();
});
_audio.addEventListener('pause',function(){
  $audio.state = 'paused';
  notifyListeners();
});
_audio.addEventListener('loadedmetadata',function(e){
  var duration = _audio.duration - ($audio.currentTrack.startSeconds);
  if($audio.currentTrack.endSeconds){
    duration = $audio.currentTrack.endSeconds - $audio.currentTrack.startSeconds;
  }
  $audio.currentTrack.duration = duration;
  $audio.seek(0);
  notifyListeners();
});
_audio.addEventListener('timeupdate',function(e){
  $audio.currentTrack.time = (_audio.currentTime - ($audio.currentTrack.startSeconds));
  if($audio.currentTrack.endSeconds && ($audio.currentTrack.endSeconds < _audio.currentTime)){
    _audio.pause();
    if($audio.hasNext()){
      $audio.playNext();
    }
    else{
      $audio.seek(0);
    }
  }
  notifyListeners();
});
_audio.addEventListener('canplay', function(e) {
  if(window.device && window.device.platform == 'iOS'){
    $audio.seek(0); // ONLY run on iOS. Required for iOS, but breaks on Android and web
  }
});
_audio.addEventListener('loadstart', function(e) {
  $audio.buffering = true;
})
_audio.addEventListener('error', function(e) {
  $audio.buffering = false;
})
_audio.addEventListener('loadeddata', function(e) {
  $audio.buffering = false;
})
_audio.addEventListener('ended', function() {
  if($audio.hasNext()){
    $audio.playNext();
  }
  else{
    _audio.pause();
    $audio.seek(0);
  }
})

var $audio = {
  playlist: null,
  trackIndex: null,
  state: null,
  currentTrack: null,
  currentTrackDuration: null,
  currentTime: null,
  buffering: false,
  isPlaying() {
    return ['buffering', 'playing'].includes(this.state);
  },
  cue(playlist) {
    if(!Array.isArray(playlist)){
      playlist = [playlist];
    }
    if(!playlist || playlist.length == 0){
      throw new Error("You must provide at least one track");
    }
    this.playlist = playlist;
    this.trackIndex = 0;
    this.play();
  },
  play() {
    this.currentTrack = this.playlist[this.trackIndex];
    if(this.currentTrack.analyticsAction){
      $analytics.track(this.currentTrack.analyticsAction,this.currentTrack.analyticsId);
    }
    this.currentTrack.startSeconds = (this.currentTrack.start || 0);
    this.currentTrack.endSeconds = (this.currentTrack.end);
    _audio.src = this.currentTrack.src;
    //this.seek(0);
    _audio.play();
  },
  resume() {
    _audio.play();
  },
  pause() {
    _audio.pause();
  },
  hasNext:function(){
    return this.playlist && (this.playlist.length > (this.trackIndex + 1));
  },
  hasPrevious() {
    return this.trackIndex && this.trackIndex > 0;
  },
  playNext() {
    this.trackIndex ++;
    this.play();
  },
  playPrevious() {
    this.trackIndex --;
    this.play();
  },
  subscribe(cb) {
    listeners.push(cb);
  },
  seek(time) {
    _audio.currentTime = (this.currentTrack.startSeconds) + time;
  }
};

export default $audio;
