<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>异象</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">{{ translate.labels["app.branding.onelord"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.onechurch"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.oneword"] }}</div>
      <!-- <div class="brand-words">{{ translate.labels["app.branding.onecity"] }}</div> -->
    </div>
    <h3>使命</h3>
    <ol>
      <li>一同聆听上帝的话语，展示合一的力量</li>
      <li>经历生命转化与更新</li>
      <li>透过每天聆听圣经建立门徒</li>
      <li>出去接触将会信主的人</li>
      <li>实现「异象 2033」：全球 99.9% 人口能以母语聆听圣经。</li>
    </ol>
    <p>我们来合力实现「异象 2033」：一同在2033年之前为各民各族的母语录製神的话语，免费供应至世界每个角落！</p>
     <h3> 关于我们 </h3>
     <p>「一源」聆听圣经运动这粿芥菜种，是由「听信真道」亚洲及前香港福音团契在2019年一同创办的；每年透过召集过百间教会，在47天大斋期藉著神的话语发挥信徒合一的精神，从而展示基督徒同属一个教会、同属一个基督身体的信念。</p>
     <p>当我们一起聆听和默想神的话语，我们的心会被触摸，会变得火热，生命会经历转化和更新；同时被提醒要肩负往地极作见证的使命。</p>
     <p>Westside Family Church 主任牧师 Dr. Randy Frazee 受「一源」运动的异象启发，还有香港大学音乐系教授 Dr. Daniel KL Chua为回应2021的呼召，分别召集了跨国城市来参与「一源」运动，以及为「一源」运动创作了主题曲《唯一的基督》。荣耀归予上帝。「一源」已经成为了全球的运动！</p>
     <p>《唯一的基督》紧贴圣经的真理，展现由马太福音廿八章大使命到啓示录廿二章新耶路撒冷的壮丽旅程，带出上帝与教会的合一、上帝国度的异象和神国度的大使命，正是要由合一精神去推动 。</p>
     <p>在「听信真道」事工区域团队的共同努力下，「一源」聆听圣经应用程式将增加马来语、泰米尔语和越南语版。</p>
     <iframe src="https://youtube.com/embed/9T0qxGjhdd0"></iframe>

     <h3>3 种你可以参与的方法:</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>分享</h4>
       <p>分享这段影片给5位朋友，藉社交媒体平台把这信息上传开去。</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>祷告</h4>
       <p>和我们一起祷告。祈求神帮助「异象2033」能够在这一代实现。</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>奉献</h4>
       <p>慷慨地为基督改变世界一同实现为世上各民各族提供圣经的异象！</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">奉献</ion-button>
     </div>

     <p>想进一步了解「一源」运动，欢迎浏览：www.onehtw.com，电邮： hello@faithcomesbyhearing.asia</p>

     <h4>「听信真道亚洲 」（ Faith Comes By Hearing (FCBH) Asia ）</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>「听信真道 亚洲 」是美国新墨西哥州阿布奎基的「国际语音圣经录音事工」的成员之一。这个事工已完成录製 1,700 多种语言的语音圣经，在50 年来免费供应到全球各地，让每个人以自己的母语听到纯全的神的话语，并著重照顾贫困人士和文盲的需要。在尖端的在线和移动技术及太阳能发电播放设备的支援下，其资源目前几乎已被每个国家广泛应用。BIBLE.IS 是一个流行的应用程序，备有各种最新版本录音的语音版圣经，适合每个使用者。「听信真道」致力实现「异象 2033 」的目标：地球上 99.9% 人口到了2033年，都能以母语聆听圣经，各族各方能够认识耶稣是主。</p>
     <p>「听信真道」连续十多年都获得美国独立慈善评估机构（ Charity Navigator ）评估为高评级慈善机构。</p>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
