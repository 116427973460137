<template>
  <div>
    <app-spinner v-if="!isLoaded"></app-spinner>
    <ion-list v-if="testimonies && isLoaded">
      <ion-item v-for="item in displayTestimonies" :key="item.id" v-router-link="{ name: 'testimony-item', params: { id: item.id } }" lines="none">
        <ion-label class="one-text-wrap">
          <h3>{{ item.title }}</h3>
          <div class="message">{{ item.message | truncate(70, '...') }}</div>
          <div class="timestamp">{{ item.relative_date }}</div>
        </ion-label>
        <ion-badge slot="end" v-if="!history.hasRead('testimony.' + item.id, item.created)">{{ translate.labels["app.general.unreadStatus"] }}</ion-badge>
      </ion-item>
    </ion-list>
    <div class="item item-text-wrap" v-if="testimonies && testimonies.length == 0">
      <p>{{ translate.labels["app.testimonies.placeholder"] }}</p>
    </div>
    <div v-if="!limit">
      <ion-infinite-scroll id="infinite-scroll" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>
  </div>
</template>
<script>
  import $history from "@/services/history";
  import $api from "@/services/api";
  import $analytics from "@/services/analytics";
  import AppSpinner from "@/components/AppSpinner";
  import { mapState } from 'vuex';

  export default{
    components:{
      AppSpinner
    },

    props: ['limit'],

    data() {
      return {
        items: null,
        history: $history,
        perPage: 10,
        isLoaded: false,
      }
    },

    filters: {
      truncate (text, length, suffix){
        if (!text) return ''
        suffix = suffix || '...';
        text = text.toString();
        return text.substring(0, length) + suffix;
      },
    },

    created() {
      this.refreshData();
    },

    computed: {
      ...mapState({
        translate: state => state.translations.translate,
        testimonies: state => state.global.testimonies,
      }),

      displayTestimonies() {
        if(this.limit) {
          return this.testimonies.slice(0, this.limit).filter((item) => {
            // Only show 30 days if limited
            return (Date.now() - (item.created)) < (1000 * 60 * 60 * 24 * 30);
          });
        }

        if(this.perPage) {
          return this.testimonies.slice(0, this.perPage);
        }

        return this.testimonies;
      },
    },

    methods:{
      refreshData() {
        const getTestimonies = $api.get('/testimonies').then((testimonies) => {
          let data = testimonies.sort((a,b) => {
            return b.created - a.created;
          }).map((item) => {
            item.read = $history.hasRead('testimony.' + item.id, item.created);
            var elapsed = (Date.now() - new Date(item.created).getTime());
            if(elapsed < (1000*60*60*24)){
              item.relative_date = 'Today';
            } else if(elapsed < (1000*60*60*24*2)){
              item.relative_date = 'Yesterday';
            } else {
              item.relative_date = Math.floor(elapsed/(1000*60*60*24)) + ' days ago';
            }
            return item;
          });

          Promise.all([getTestimonies]).then(() => {
            this.$store.commit('global/setTestimonies', data);
            this.isLoaded = true;
            this.$store.commit('global/getUnreadTestimoniesCount');
          })
        });
      },


      loadData(event) {
        this.$nextTick(() => {
          this.perPage = this.perPage + 10
          event.target.complete();
        });
      },
    },
  }
</script>

<style scoped>
  .message {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--ion-color-medium);
  }
  .timestamp
  {
    color: var(--ion-color-primary-shade);
  }
</style>
