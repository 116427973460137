<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ translate.labels['app.settings.preferences'] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label>{{ translate.labels['app.settings.theme'] }}</ion-label>
        <ion-select placeholder="Select one..."
          :value="theme"
          @ionChange="onThemeChange"
        >
          <ion-select-option value="default">{{ translate.labels['app.settings.default'] }}</ion-select-option>
          <ion-select-option value="lightmode">{{ translate.labels['app.settings.lightmode'] }}</ion-select-option>
          <ion-select-option value="darkmode">{{ translate.labels['app.settings.darkmode'] }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate.labels['app.settings.fontsize'] }}</ion-label>
        <ion-select placeholder="Select one..."
          :value="fontsize"
          @ionChange="onFontSizeChange"
        >
          <ion-select-option value="default">{{ translate.labels['app.settings.default'] }}</ion-select-option>
          <ion-select-option value="large">{{ translate.labels['app.settings.large'] }}</ion-select-option>
          <ion-select-option value="xlarge">{{ translate.labels['app.settings.xlarge'] }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>{{ translate.labels['app.settings.fontfamily'] }}</ion-label>
        <ion-select placeholder="Select one..."
          :value="fontfamily"
          @ionChange="onFontFamilyChange"
        >
          <ion-select-option value="default">{{ translate.labels['app.settings.default'] }}</ion-select-option>
          <ion-select-option value="opensans">Open Sans</ion-select-option>
          <ion-select-option value="roboto">Roboto</ion-select-option>
          <ion-select-option value="merriweather">Merriweather</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-content>
  </ion-page>
</template>
<script>
import $analytics from '@/services/analytics';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      theme: localStorage.theme || 'default',
      fontsize: localStorage.fontsize || 'default',
      fontfamily: localStorage.fontfamily || 'default',
    };
  },
  components: {
  },
  computed: {
    ...mapState('translations', {
       translate: 'translate',
    }),
  },
  methods: {
    onThemeChange($event) {
      this.theme = $event.target.value;
      localStorage.theme = this.theme;
      this.$store.commit('global/updateTheme', this.theme);
    },
    onFontSizeChange($event) {
      this.fontsize = $event.target.value;
      localStorage.fontsize = this.fontsize;
      this.$store.commit('global/updateFontSize', this.fontsize);
    },
    onFontFamilyChange($event) {
      this.fontfamily = $event.target.value;
      localStorage.fontfamily = this.fontfamily;
      this.$store.commit('global/updateFontFamily', this.fontfamily);
    },
  },
  created() {
    // $analytics.track('language-settings-view');
  },
};
</script>
