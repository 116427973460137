<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title v-if="item">{{item.title}}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="share()">{{translate.labels['app.general.share']}}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-spinner v-if="!item"></app-spinner>
      <div v-if="item">
        <p>{{item.relative_date}}</p>
        <div class="message one-text-wrap" v-html="messageHtml" @click="handleClick($event)"></div>
        <div v-if="item.youtube">
          <YoutubePlayer :src="item.youtube"></YoutubePlayer>
        </div>
        <div v-if="item.actionlabel">
          <ion-button fill="clear" @click="visit(item.actionlink)" expand="block">{{item.actionlabel}}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>

</template>
<script>
  import linkifyHtml from 'linkifyjs/html';
  import $history from "@/services/history.js";
  import $analytics from "@/services/analytics.js";
  import $api from "@/services/api.js";
  import YoutubePlayer from "@/components/YoutubePlayer";
  import AppSpinner from "@/components/AppSpinner";
  import { mapState } from 'vuex';

  export default {
    components:{
      YoutubePlayer,
      AppSpinner
    },
    computed: {
      ...mapState('translations', {
          translate: 'translate',
      }),
      messageHtml() {
        return this.item && linkifyHtml(this.item.message.replace('/n', '/n/n/n'), {
          defaultProtocol: 'https',
          target: {
            url: '_blank',
          },
        });
      },
    },
    data:function(){
      return {
        item:null
      }
    },
    methods:{
      refreshData:function(){
        $api.get("/testimonies/" + this.$route.params.id).then((item)=>{
          this.item = item;
        })
      },
      visit:function(url){
        // !!window.cordova ? cordova.InAppBrowser.open(encodeURI(url), '_system') : window.open(url,"_blank");
        this.$root.$emit('openurl', url);
      },
      share() {
        const url = `${process.env.VUE_APP_PREVIEW_ENDPOINT}/news/${this.$route.params.id}`;
        if(!window.plugins || !window.plugins.socialsharing){
          console.log('Share', url);
        }
        else{
          window.plugins.socialsharing.shareWithOptions({url:url}, console.log, console.log);
        }
        $analytics.track('share-news');
      },
      handleClick(event){
        event.preventDefault();
        if (event.target && event.target.href) {
          window.open(event.target.href, '_system');
        }
      },
    },
    created:function(){
      this.refreshData();
      $analytics.track("testimony-item",this.$route.params.id);

      if (!$history.hasRead('testimony.' + this.$route.params.id)) {
        $history.markRead("testimony." + this.$route.params.id);
        this.$store.commit('global/updateUnreadTestimoniesCount', this.$store.state.global.unreadNewsCount - 1);
      }
    }
  }
</script>
<style scoped>
  .message
  {
    white-space: pre-wrap;
  }
</style>
