<template>
  <ion-modal>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="medium" @click="cancel">Cancel</ion-button>
        </ion-buttons>
        <ion-title>Modal</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <p>{{ url }}</p>
      <iframe :src="url" class="browser"></iframe>
    </ion-content>
  </ion-modal>
</template>

<script>
import { modalController } from "@ionic/vue";

export default {
  data() {
    return {
      nurl: 'none',
    };
  },
  props: {
    parent: {
      type: Object,
    },
    url: {
      type: String,
    },
  },
  watch: {
    url() {
      console.log('URL CHANGED TO ', this.url);
    },
  },
  methods: {
    cancel() {
      this.$parent.closeBrowser();
    },
  },
  mounted() {
    console.log('MOUNTED', this.url, this.$url);
  },
}
</script>

<style lang="css" scoped>
.browser {
  width: 100%;
  height: 100%;
}
</style>
