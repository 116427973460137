<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3> 異象</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">{{ translate.labels["app.branding.onelord"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.onechurch"] }}</div>
      <div class="brand-words">{{ translate.labels["app.branding.oneword"] }}</div>
    </div>
    <h3>使命</h3>
    <ol>
      <li>一同聆聽上帝的話語，展示合一的力量</li>
      <li>經歷生命轉化與更新</li>
      <li>透過每天聆聽聖經建立門徒</li>
      <li>出去接觸將會信主的人</li>
      <li>實現「異象 2033」：全球 99.9% 人口能以母語聆聽聖經。</li>
    </ol>
    <p>我們來合力實現「異象 2033」：一同在2033年之前為各民各族的母語錄製神的話語，免費供應至世界每個角落！</p>
    <h3> 關於我們 </h3>
    <p>「一源」聆聽聖經運動這粿芥菜種，是由「聽信真道」亞洲及前香港福音團契在2019年一同創辦的；每年透過召集過百間教會，在47天大齋期藉著神的話語發揮信徒合一的精神，從而展示基督徒同屬一個教會、同屬一個基督身體的信念。</p>
    <p>當我們一起聆聽和默想神的話語，我們的心會被觸摸，會變得火熱，生命會經歷轉化和更新；同時被提醒要肩負往地極作見證的使命。</p>
    <p>Westside Family Church 主任牧師 Dr. Randy Frazee 受「一源」運動的異象啟發，還有香港大學音樂系教授 Dr. Daniel KL Chua為回應2021的呼召，分別召集了跨國城市來參與「一源」運動，以及為「一源」運動創作了主題曲《唯一的基督》。榮耀歸予上帝。「一源」已經成為了全球的運動！</p>
    <p>2021 年，全球 32 個城市加入了 「一源運動」，其中包括來自亞洲國家（新加坡、馬來西亞、越南）和非洲加納的城市</p>
    <p>《唯一的基督》緊貼聖經的真理，展現由馬太福音廿八章大使命到啓示錄廿二章新耶路撒冷的壯麗旅程，帶出上帝與教會的合一、上帝國度的異象和神國度的大使命，正是要由合一精神去推動 。</p>
    <p>在「聽信真道」事工區域團隊的共同努力下，「一源」聆聽聖經應用程式將增加馬來語、泰米爾語和越南語版。</p>
    <iframe src="https://youtube.com/embed/nv4jQxkdW5o"></iframe>
    <!-- <p>讚美主掌管最適切的時間，在本年2月17日讓我們在「一源」2021聆聽聖經運動啟動之際收到一筆5佰萬元的配對捐款。故此我們相信，上帝有訊息賜給「一源」運動的參與者: 當你聆聽上帝的話語時，你感到受祝福嗎? 你希望擴展這份祝福，讓上帝的話臨到弱小、卑微及被遺忘的人嗎?</p>
    <p>倘若你們當中有400位人士回應這個配對挑戰：持續在5個月每月內奉獻至少500港元，並呼籲至少5 位親友一同參與這捐款行動，亞洲將有千千萬萬的人因此獲得你們的祝福，有機會聽到上帝的話語，從而生命得著改變，同時也幫助我們向實現「異象 2033」和耶穌給我們的大使命邁進一步。</p> -->

    <h3>3 種你可以參與的方法</h3>

    <div class="action-steps">

      <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>分享</h4>
       <p>分享這段影片給5位朋友，藉社交媒體平台把這信息上傳開去。</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>禱告</h4>
       <p>和我們一起禱告。 祈求神幫助「異象2033」能夠在這一代實現。</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>奉獻</h4>
       <p>慷慨地為基督改變世界一同實現為世上各民各族提供聖經的異象！</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">奉獻</ion-button>
    </div>

     <p>想進一步了解「一源」運動，歡迎瀏覽：www.onehtw.com，電郵： hello@faithcomesbyhearing.asia</p>

     <h4>「聽信真道亞洲 」（ Faith Comes By Hearing (FCBH) Asia ）</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>「聽信真道 亞洲 」是美國新墨西哥州阿布奎基的「國際語音聖經錄音事工」的成員之一。這個事工已完成錄製 1,700 多種語言的語音聖經，在50 年來免費供應到全球各地，讓每個人以自己的母語聽到純全的神的話語，並著重照顧貧困人士和文盲的需要。在尖端的在線和移動技術及太陽能發電播放設備的支援下，其資源目前幾乎已被每個國家廣泛應用。BIBLE.IS 是一個流行的應用程序，備有各種最新版本錄音的語音版聖經，適合每個使用者。「聽信真道」致力實現「異象 2033 」的目標：地球上 99.9% 人口到了2033年，都能以母語聆聽聖經，各族各方能夠認識耶穌是主。</p>
     <p>「聽信真道」連續十多年都獲得美國獨立慈善評估機構（ Charity Navigator ）評估為高評級慈善機構。</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
