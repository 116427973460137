<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>ANG PANANAW</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">ISANG Panginoon</div>
      <div class="brand-words">ISANG Simbahan</div>
      <div class="brand-words">ISANG Salita</div>
    </div>
    <h3>ANG MISYON</h3>
    <ol>
      <li>Pagpapahayag ng Pagkakaisa habang ang lahat ng mananampalataya ay sama-samang nakikinig sa Salita ng Diyos</li>
      <li>Pagbabagong buhay at muling pagbabangon</li>
      <li>Ang pagiging disipulo sa pamamagitan ng ugali ng araw-araw na pakikinig sa Bibliya</li>
      <li>Pag-abot  sa mga hindi mananampalataya.</li>
      <li>Matupad ang Vision 2033: 99% ng mga tao sa buong mundo ay nakakarinig ng Bibliya sa kanilang sariling wika ng puso.</li>
    </ol>
    <p>
      <span>Sama-sama nating isasakatuparan ang Vision 2033:</span>
      <a @click="openLink('https://www.faithcomesbyhearing.com/about/our-mission')">https://www.faithcomesbyhearing.com/about/our-mission</a>
      <span>na itala ang Salita ng Diyos sa bawat pusong wika ng mundo na nangangailangan nito sa 2033 at gawin itong malayang magagamit!</span>
     </p>
     <h3>SINO KAMI</h3>
     <p>Isinilang ng isang maliit na buto ng mustasa noong 2019, ang ONE Hearing the Word Campaign (ONE Campaign) ay kasamang itinatag ng Faith Comes By Hearing Asia (FCBH Asia) at ng dating Hong Kong Evangelical Fellowship (HKEF). Ang ONE Campaign, isang kampanya sa pakikinig ng bibliya na nagtitipon ng daan-daang simbahan sa Hong Kong sa panahon ng 47-araw na Kuwaresma upang ipahayag ang pagkakaisa ng mga mananampalataya sa ilalim ng Salita ng Diyos, ay naglalayong ipakita na ang mga Kristiyano ay ISANG Simbahan at mayroon lamang Isang Katawan ni Kristo.</p>
     <p>Habang tayo ay nakikinig at nagbubulay-bulay sa Salita ng Diyos, ang mga puso ay maaantig, ang mga espiritu ay mag-aapoy, at magkakaroon ng pagbabagong-buhay at pagbabago ng mga buhay, sapagkat tayo ay paalalahanan ng ating tawag na maging mga saksi hanggang sa dulo ng mundo.</p>
     <p>Sa inspirasyon ng Vision of ONE Campaign, parehong sina Rev. Dr. Randy Frazee, Senior Pastor ng Westside Family Church, at Dr. Daniel KL Chua, Chair Professor of Music sa The University of Hong Kong ay tumugon sa Panawagan sa taong 2021 sa pamamagitan ng pag-titipun-tipun  internasyonal ng mga lungsod upang lumahok sa ONE Campaign at bumuo ng isang temang Kanta para sa ONE Campaign (tinatawag na "ONE Song") ayon sa pagkakabanggit. Luwalhati sa Diyos, ang ONE Campaign ay naging isang pandaigdigang kilusan!</p>
     <p>Noong 2021, 32 lungsod mula sa buong mundo ang sumali sa ONE Campaign kabilang ang mga mula sa mga bansa sa Asya - Singapore, Malaysia, Vietnam, at Ghana mula sa Africa.</p>
     <p>Ang ISANG Awit ay malapit na sumangguni sa Kasulatan, na naglalahad ng epikong paglalakbay mula sa Dakilang Utos sa Mateo 28 hanggang sa Bagong Jerusalem sa Pahayag 22, upang ipahayag ang pagkakaisa ng Diyos at ng kanyang Simbahan, at kung paano ang pagkakaisa na iyon ang nagtutulak na puwersa para sa pangitain at misyon ng Diyos. Kaharian.</p>
     <p>Sa sama-samang pagsisikap ng Faith Comes By Hearing pangkat ng rehiyon, tatlo pang wika (Malay, Tamil, at Vietnamese) ang idaragdag sa app na “ONE Hearing the Word” (ONE) mula sa taong 2023.</p>
     <iframe src="https://iframe.mediadelivery.net/embed/100722/56eda1d0-9e57-47ff-8a06-f8b72e687ffe"></iframe>

     <h3>3 Paraan na Makakatulong Ka</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>Pagbabahagi</h4>
       <p>Ibahagi ang pananaw na ito sa mga kaibigan at tumulong sa pagpapalaganap ng salita sa sosyal medya.</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>Pagdarasal</h4>
       <p>Sumali ka sa pagdarasal na ang gawaing sinimulan ng Diyos tungo sa Ang Vision 2033 ay matutupad sa henerasyong ito.</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>Pagbibigay</h4>
       <p>Magbigay nang bukas-palad at masaya at maging bahagi ng pagbabago ng mundo para kay Kristo.</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">Pagbibigay</ion-button>

     </div>

     <h3>Karagdagang impormasyon</h3>
     <p>Upang malaman ang higit pa tungkol sa ONE Campaign, mangyaring bisitahin ang aming website: www.onehtw.com, makipag-ugnayan sa amin sa pamamagitan ng email: hello@faithcomesbyhearing.asia.</p>

     <h4>Ang Pananampalataya ay Dumarating sa Pakikinig (FCBH)</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>Ang FCBH Asia ay bahagi ng pamilya ng FCBH sa Albuquerque, U.S.A. na isang internasyonal na audio Bible recording ministry at may mga audio Bible na magagamit  sa mahigit 1,700 na wika. Sa loob ng 50 taon, ito ay nakatuon sa pagbibigay sa lahat, lalo na sa mga mahihirap at hindi marunong bumasa at sumulat ng libreng access sa dalisay na Salita ng Diyos sa kanilang sariling wika sa puso. Mula sa mga makabagong pagsasalin at teleponong selular  at ibang  teknolohiya hanggang sa mga pinapagana ng solar na mga aparato, ang mga mapagkukunan nito ay kasalukuyang ginagamit sa halos lahat ng bansa sa mundo. Sa pamamagitan ng sikat na bible App nito, BIBLE.IS, ito ay nakatuon at gagawing mapakinabangan ang buong pagbabago nang  pagtatala  ng Salita ng Diyos sa bawat wikang naitala. Isa ito sa mga misyon ng FCBH na maisakatuparan ang Vision 2033, na naglalayong magkaroon ng Salita ng Diyos para sa 99.9% ng populasyon ng mundo upang malaman ng bawat wika at bawat tribo na si Hesus ay Panginoon. Ang FCBH ay isa sa kilala at nangu-ngunang  kawanggawa kasama ang pang-publikong kawanggawa ‘Charity Navigator’ sa loob ng higit sa 10 magkakasunod na taon.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
