const apiroot = 'https://api.onehtw.com';
// const apiroot = 'http://localhost:8888';

export default {
  track(action,id) {
    const payload = {
      action,
      id,
      church: localStorage.church,
      language: localStorage.language || 'ENG',
      user: localStorage.userid,
      country: localStorage.country,
    };
    return fetch(`${apiroot}/analytics/track`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body:JSON.stringify(payload),
    });
  }
}
