<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>비전</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">한 분의 주님</div>
      <div class="brand-words">하나의 교회</div>
      <div class="brand-words">하나의 말씀</div>
    </div>
    <h3>미션</h3>
    <ol>
      <li>하나님의 말씀을 모두 함께 듣는 연합의 표현</li>
      <li>삶의 변화와 부흥</li>
      <li>매일 성경듣기를 통한 제자화</li>
      <li>초신자 전도</li>
      <li>비전 2033 성취: 전 세계 인구의 99%가 자신의 심정언어로 성경말씀을 들을 수 있게 한다.</li>
    </ol>
    <p>
      <span>우리는 함께 비전 2033을 성취할 것입니다: </span>
      <a @click="openLink('https://www.faithcomesbyhearing.com/about/our-mission')">https://www.faithcomesbyhearing.com/about/our-mission</a>
      <span>2033년까지 세계의 모든 언어로 하나님의 말씀을 녹음하여 필요한 사람이 무료로 사용할 수 있도록 한다는 비전입니다!</span>
     </p>
     <h3>캠페인 소개</h3>
     <p>2019년 작은 겨자씨 한 알 만한 크기로 탄생한 ONE Hearing the Word 캠페인(ONE 캠페인)은 Faith Comes By Hearing Asia(FCBH Asia)와 전 홍콩복음주의 연합(HKEF)에 의해 공동으로 설립되었습니다. 47일의 사순절 기간 동안 홍콩의 수백 개 교회가 모여 하나님의 말씀 아래 연합하는 성경듣기 캠페인으로, ONE 캠페인은 모든 기독교인이 하나의 교회이며 그리스도의 한 몸임을 보여주는 것을 목표로 합니다.</p>
     <p>우리가 함께 하나님의 말씀을 듣고 묵상할 때 마음이 감동되고, 영에 불이 붙으며, 삶의 부흥과 변화가 있을 것입니다. 땅끝까지 증인이 되라는 부르심을 기억하게 될 것이기 때문입니다.</p>
     <p>ONE 캠페인의 비전에서 영감을 받아 Westside Family Church의 담임목사인 랜디 프레이지( Randy Frazee) 목사님과 홍콩대학교 음악대학 석좌교수인 다니엘 추아 (Dr. Daniel KL Chua) 교수님은 2021년 국제 도시들의 ONE캠페인 참여를 독려하며 ONE 캠페인 주제가("ONE Song")를 작곡하면서 각각 행동으로 소명에 응답하였습니다. 하나님께 영광을! ONE 캠페인은 이제 글로벌 운동이 되었습니다!</p>
     <p>2021년에 싱가포르, 말레이시아, 베트남과 같은 아시아 국가들과 더불어 아프리카 가나 등 전 세계 32개 도시가 ONE 캠페인에 참여했습니다.</p>
     <p>ONE 주제가는 마태복음 28장의 지상명령에서 요한계시록 22장의 새 예루살렘까지 서사적 여정이 펼쳐지는 성경말씀을 밀접하게 조명하여 하나님과 그의 교회의 일치를 노래하고 그 일치가 어떻게 하나님 왕국의 비전과 사명의 원동력이 되는지를 잘 표현하고 있습니다.</p>
     <p>Faith Comes By Hearing 아시아지역 팀의 공동 노력으로 2023년부터 “ONE Hearing the Word” (ONE) 앱에 3개 언어(말레이어, 타밀어 및 베트남어)가 추가됩니다.</p>
     <iframe src="https://iframe.mediadelivery.net/embed/100722/64307425-8d94-44f1-8814-de9059a282d1"></iframe>

     <h3>당신이 도울 수 있는 3가지 방법</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>공유</h4>
       <p>소셜 미디어 상에서도 소문 내주세요.</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>기도</h4>
       <p>하나님께서 시작하신 비전 2033이</p>
       <p>이 세대에 실현되길 함께 기도해주세요.</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>기부</h4>
       <p>아낌없이 기쁜 마음으로 베풀면서</p>
       <p>그리스도를 위해 세상을 바꾸는 일에 동참하세요.</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">기부하러가기</ion-button>

     </div>

     <h3>자세히 알아보기</h3>
     <p>저희 웹사이트 www.onehtw.com을 방문하거나 이메일 (hello@faithcomesbyhearing.asia)을 통해 문의하시면 ONE 캠페인에 대해 더 자세히 알아보실 수 있습니다.</p>

     <h4>믿음은 들음에서 난다(Faith Comes By Hearing (FCBH)</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>FCBH Asia는 미국 앨버커키에 본부를 두고 현재1,700개 이상의 언어로 성경말씀을 제공하고 있는 국제적 오디오 성경녹음 사역인 FCBH 가족의 일원입니다. 저희는 50년 동안 지구 상의 모든 사람들, 특히 가난하고 문맹인 사람들이 자신의 심정언어로 순수한 하나님의 말씀을 무료로 접할 수 있도록 노력해 왔습니다. 최첨단의 온라인 및 모바일 기술에서 태양열 장치에 이르기까지 현재 전 세계 거의 모든 국가에서 가능한 리소스를 사용하고 있습니다. 또한 인기 있는 성경 앱인 BIBLE.IS를 통해 현재도 녹음되고 있는 모든 언어를 계속적으로 업데이트하여 하나님의 말씀을 제공하고 있습니다. 비전 2033을 실현하는 것은 FCBH의 사명 중 하나입니다. 비전 2033은 전 세계 인구의 99.9%가 2033년까지 하나님의 말씀을 듣도록 하는 것으로, 모든 언어와 모든 부족이 예수님이 주님이심을 알 수 있도록 하는 것을 목표로 합니다. FCBH는 또한 Charity Navigator와 함께 하고 있는 10년 이상 연속 최고의 자선 단체 중 하나입니다.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
