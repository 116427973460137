<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ translate.labels['app.settings.language'] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label class="one-text-wrap">
          <p>{{ translate.labels['app.welcome.introLanguage'] }}</p>
        </ion-label>
      </ion-item>
      <LanguageSelector @change="next()"></LanguageSelector>
    </ion-content>
  </ion-page>
</template>
<script>
import $analytics from '@/services/analytics';
import $api from '@/services/api';
import LanguageSelector from '@/components/LanguageSelector';
import { mapState } from 'vuex';

export default {
  components:{
    LanguageSelector
  },
  computed: {
    ...mapState('translations', {
       translate: 'translate',
    }),
  },
  methods:{
    next:function(){
      this.$router.push({
        name: 'onboarding-country'
      });
    }
  },
  created:function(){
    $analytics.track('language-settings-view');
  }
};
</script>
