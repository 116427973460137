<template>
  <div id="player" v-if="src"></div>
</template>

<script>
  import $analytics from "@/services/analytics";

  export default {
    name: 'youtube-player',

    props: [
      'src',
      'analyticsId',
      'analyticsAction'
    ],

    created() {
      if(this.analyticsAction) {
        $analytics.track(this.analyticsAction, this.analyticsId);
      }
    },

    data() {
      return {
        player: null,
      }
    },

    mounted() {
      if(!this.hasYouTubeFrameAPI()) {
        this.injectYouTubeFrameAPI();
      } else {
        window.onYouTubeIframeAPIReady();
      }

      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
    },

    methods: {
      hasYouTubeFrameAPI(){
        return !!(document.getElementsByClassName('yt-frame-api').length);
      },

      injectYouTubeFrameAPI(){
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';
          tag.className = 'yt-frame-api';
          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
      },

      onYouTubeIframeAPIReady() {
        this.player =  new YT.Player('player', {
          height: '200',
          width: '100%',
          videoId: this.youTubeGetID(this.src),
          playerVars: {
            rel: 0
          },
          events: {
            'onStateChange': this.onPlayerStateChange
          }
        });
      },

      onPlayerStateChange(event) {
        if (event.data === 0) {
          this.player.seekTo(0);
          this.player.stopVideo();
        }
      },

      youTubeGetID(url){
        let videoId = '';
        url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if(url[2] !== undefined) {
          videoId = url[2].split(/[^0-9a-z_\-]/i);
          videoId = videoId[0];
        } else {
          videoId = url;
        }
        return videoId;
      }
    }
  }
</script>

<style scoped>
  .youtube-player{
    height: 200px;
    width: 100%;
    background-color: black;
  }
</style>
