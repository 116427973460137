<template>
  <div>
    <iframe src="https://youtube.com/embed/jXgwGM83O4Q"></iframe>
    <h3>VISI</h3>
    <div class="brand-header">
      <img src="@/assets/icon2021.png" alt="">
      <div class="brand-words">SATU Tuhan</div>
      <div class="brand-words">SATU Gereja</div>
      <div class="brand-words">SATU Firman</div>
    </div>
    <h3>MISI</h3>
    <ol>
      <li>Ekspresi Perpaduan apabila semua orang percaya mendengar Firman Tuhan bersama-sama</li>
      <li>Transformasi dan kebangkitan hidup</li>
      <li>Pemuridan melalui kebiasaan mendengar Alkitab setiap hari</li>
      <li>Jangkauan kepada orang-orang pra-iman</li>
      <li>Mencapai Wawasan 2033: 99% orang di dunia boleh mendengar Alkitab dalam bahasa ibunda mereka sendiri.</li>
    </ol>
    <p>
      <span>Bersama-sama kita akan mencapai Wawasan 2033: </span>
      <a @click="openLink('https://www.faithcomesbyhearing.com/about/our-mission')">https://www.faithcomesbyhearing.com/about/our-mission</a>
      <span>iaitu merekodkan Firman Tuhan dalam setiap bahasa ibunda di dunia yang memerlukannya menjelang 2033 dan menjadikannya tersedia secara percuma!</span>
     </p>
     <h3>SIAPA KITA</h3>
     <p>Dilahirkan oleh satu biji sawi kecil pada tahun 2019, ONE Hearing the Word Campaign (Kempen ONE) diasaskan bersama oleh Faith Comes By Hearing Asia (FCBH Asia) dan bekas Hong Kong Evangelical Fellowship (HKEF). Kempen ONE, ialah kempen mendengar alkitab yang menghimpunkan ratusan gereja di Hong Kong semasa Puasa 47 hari untuk menyatakan perpaduan orang percaya di bawah Firman Tuhan, bertujuan untuk menunjukkan bahawa orang Kristian adalah SATU Gereja dan hanya ada Satu Tubuh Kristus.</p>
     <p>Semasa kita mendengar dan merenungkan Firman Tuhan bersama-sama, hati akan tersentuh, roh bernyala-nyala, dan akan ada kebangkitan dan perubahan kehidupan, kerana itu kita akan diingatkan tentang panggilan kita untuk menjadi saksi ke hujung bumi.</p>
     <p>Diinspirasikan oleh Visi Kempen ONE, kedua-dua Rev. Dr. Randy Frazee, Senior Pastor Gereja Keluarga Westside dan Dr. Daniel KL Chua, Pengerusi Profesor Muzik di Universiti Hong Kong menyahut Panggilan pada tahun 2021 dengan mengadakan perhimpunan antarabangsa untuk mengambil bahagian dalam Kempen ONE, dan mencipta lagu tema untuk Kempen ONE (dipanggil "ONE Song") masing-masing. Kemuliaan bagi Tuhan, Kempen ONE  kini telah menjadi gerakan global!</p>
     <p>Pada 2021, 32 bandar dari seluruh dunia menyertai Kempen ONE termasuk dari negara Asia - Singapura, Malaysia, Vietnam dan Ghana dari Afrika.</p>
     <p>ONE Song ini merujuk Kitab Suci dengan sepenuhnya, menyingkap perjalanan epik dari Amanat Agung dalam Matius 28 ke Yerusalem Baru dalam Wahyu 22, untuk menyatakan kesatuan Tuhan dan Gereja-Nya, dan bagaimana kesatuan itu adalah pendorong yang kuat untuk visi dan misi Kerajaan Tuhan.</p>
     <p>Dengan usaha bersepadu pasukan serantau Faith Comes By Hearing, tiga lagi bahasa (Melayu, Tamil dan Vietnam) akan ditambahkan pada aplikasi "ONE Hearing the Word" (ONE) mulai tahun 2023.</p>
     <iframe src="https://iframe.mediadelivery.net/embed/100722/64307425-8d94-44f1-8814-de9059a282d1"></iframe>

     <h3>3 Cara Anda Boleh Membantu</h3>

     <div class="action-steps">
       <img class="action-icon" src="@/assets/share.png" alt="">
       <h4>Kongsi</h4>
       <p>Kongsi visi ini dengan rakan-rakan dan sebarkan berita ini di media sosial.</p>

       <img class="action-icon" src="@/assets/pray.png" alt="">
       <h4>Berdoa</h4>
       <p>Sertai kami dalam berdoa agar kerja yang dimulakan oleh Tuhan ke arah. Visi 2033 akan ditunaikan dalam generasi ini.</p>

       <img class="action-icon" src="@/assets/give.png" alt="">
       <h4>Beri</h4>
       <p>Memberi dengan murah hati dan gembira menjadi sebahagian daripada mengubah dunia untuk Kristus.</p>
       <ion-button expand="block" @click="openLink('https://faithcomesbyhearing.asia/donate')" side="start">Beri</ion-button>

     </div>

     <h3>Maklumat lanjut</h3>
     <p>Untuk mengetahui lebih lanjut tentang Kempen ONE, sila lawati laman web kami: www.onehtw.com, hubungi kami via email: hello@faithcomesbyhearing.asia</p>

     <h4>Faith Comes By Hearing (FCBH)</h4>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.com')">https://www.faithcomesbyhearing.com</a>
     </p>
     <p>
       <a @click="openLink('https://www.faithcomesbyhearing.asia')">https://www.faithcomesbyhearing.asia</a>
     </p>
     <p>FCBH Asia adalah sebahagian daripada keluarga FCBH di Albuquerque, U.S.A. yang merupakan pelayanan rakaman audio antarabangsa dan mempunyai Alkitab audio tersedia dalam lebih 1,700 bahasa. Selama 50 tahun, ia telah komited untuk menyediakan semua orang, terutamanya golongan miskin dan buta huruf mengakses secara percuma kepada Firman Tuhan yang tulen dalam bahasa ibunda mereka sendiri. Daripada teknologi dalam talian dan mudah alih yang canggih kepada peranti berkuasa solar, sumber ini kini digunakan hampir setiap negara di dunia. Melalui popularnya Aplikasi bible, BIBLE.IS, ia komited dan akan menyediakan keseluruhan rakaman Firman Tuhan yang dikemas kini dalam setiap bahasa yang dirakam. Ia adalah salah satu misi FCBH untuk merealisasikan Visi 2033, yang bertujuan untuk menyediakan Firman Tuhan untuk 99.9% penduduk dunia supaya setiap lidah, dan setiap suku dapat mengetahui bahawa Yesus adalah Tuhan. FCBH juga merupakan badan amal yang mendapat penilaian tertinggi dengan Charity Navigator selama lebih 10 tahun berturut-turut.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('translations', {
        translate: 'translate',
    }),
  },
  methods: {
    openLink(url) {
      this.$emit('open', url);
    },
  },
}
</script>
