import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router'

import Ionic from '@ionic/vue';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

import moment from 'moment';
import VueMoment from 'vue-moment';
import 'moment/locale/zh-tw';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
import 'moment/locale/id';
import 'moment/locale/tl-ph';

import '@/icons/css/styles.min.css';
import '@/style/lightmode.css';
import '@/style/darkmode.css';
import '@/style/fontsizes.css';

Vue.config.productionTip = false
Vue.use(Ionic, {
  mode: 'ios'
});


Vue.use(VueMoment, { moment });

Vue.directive('router-link', {
  inserted: function (el,binding,vnode) {
    el.addEventListener("click",function(){
      vnode.context.$router.push(binding.value);
    })
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

import $user from "@/services/user";
document.addEventListener("deviceready",function(){
  cordova && cordova.plugins && cordova.plugins.pushUpdate && cordova.plugins.pushUpdate.watchForUpdates(function(){
    if(confirm("A new version of this app is available. Update now ?")){
      if (window.navigator && window.navigator && window.navigator.app && window.navigator.exitApp) {
        window.navigator.app.exitApp();
      } else {
        console.log("DoLiveUpdate");
        window.history.go((-1*window.history.length)+1);
        cordova.plugins.pushUpdate.installUpdates();
        console.log("DoneLiveUpdate");
      }
    }
  });

  // Copy localStorage from previous versions
  if(!localStorage.lsCacheImported){
    NativeStorage.getItem("lsCache",function(data){
      var lsCache = JSON.parse(data);
      Object.keys(lsCache).forEach(function(cacheKey){
        localStorage[cacheKey] = lsCache[cacheKey];
      })
    },console.error);
    localStorage.lsCacheImported = Date.now();
  }

  // Enable push notifications
  if(window.PushNotification){
     var pushManager = PushNotification.init({
       android: {
         senderID: '988181353812',
         icon: 'notification',
         iconColor: 'blue',
       },
       ios: {
         alert: true,
         badge: true,
         sound: true,
         clearBadge: true
       }
     });
     pushManager.on('registration', function(data) {
       $user.token = data.registrationId;
       $user.save();
     });
     pushManager.on('notification', function(notification){
       var payload = notification.additionalData;
       if(!payload.foreground && payload.path){
         window.location.href = 'index.html#' + payload.path;
       }
     })
   }

   // Configure cordova defaults
  if(window.cordova && window.Keyboard){
    window.Keyboard.hideKeyboardAccessoryBar(false);
  }
  if(window.cordova && StatusBar){
    // StatusBar.styleDefault();
    StatusBar.overlaysWebView(false);
    StatusBar.backgroundColorByHexString('#444444');
  }
  if (window.cordova && cordova.platformId == 'android') {
    // On Android there was a bug where you couldn't unselect highlighted text, and this seemed to fix it.
    document.body.addEventListener('click', function(e){
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    });
  }
});

// HANDLING OFFLINE STATUS
function handleNetwork(){
  if(!navigator.onLine) {
    //window.location.href = "./views/offline.html";
    console.log('TODO: Handle offline status');
  }
}
handleNetwork();
window.addEventListener('offline' ,handleNetwork);
