import Vue from 'vue';
import Vuex from 'vuex';
import translations from './modules/translations';
import global from './modules/global';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    translations,
    global,
  },
});
