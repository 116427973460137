<template>
  <div id="app" :class="classes">
    <ion-app v-if="$store.state.global.launchApp">
      <ion-vue-router></ion-vue-router>
    </ion-app>
    <app-offline />
  </div>
</template>

<script>
import $api from '@/services/api.js';
import AppOffline from '@/components/Offline.vue';
import { mapState } from 'vuex';
import Browser from './components/Browser.vue';

export default {
  data() {
    return {
      browserModal: null,
    };
  },
  components: {
    AppOffline,
  },
  computed: {
    ...mapState('global', {
      theme: 'theme',
      fontsize: 'fontsize',
      fontfamily: 'fontfamily',
    }),
    classes() {
      console.log(this.theme, this.fontsize, this.fontfamily);
      return `theme-${this.theme} font-${this.fontsize} fontfamily-${this.fontfamily}`;
    },
  },

  async created() {
    try {
      await this.$store.dispatch('translations/getLabels');
      this.$store.commit('global/setLaunchAppStatus', true);
      document.addEventListener('deviceready', () => {
        // Hide splashscreen for cordova
        navigator.splashscreen && navigator.splashscreen.hide();
      });
    } catch(err) {
      this.$store.commit('global/setLaunchAppStatus', false);
    }

    const momentMap = {
      ENG: 'en',
      TGL: 'tl-ph',
      KOR: 'ko',
      IND: 'id',
      YUH: 'zh-tw',
      CHN: 'zh-cn',
      VIE: 'vi',
      MAY: 'ms',
      TAM: 'en',
    }
    const momentId = momentMap[localStorage.language];
    this.$moment.locale(momentId);
  },
  methods: {
    async showBrowser(url) {
      this.browserModal = await this.$ionic.modalController.create({
        name: 'browser',
        component: Browser,
        componentProps: {
          parent: this,
          propsData: {
            url,
          },
        },
      });
      this.browserModal.present();
    },
    async showNotice(url) {
      let buttons = [];
      let message = `Due to an unexpected bug, we are not able to open this link automatically. Please open ${url} in your browser.`
      if (navigator.clipboard && navigator.clipboard.writeText) {
        message = `Due to an unexpected bug, we are not able to open this link automatically. Please copy ${url} and open it in your browser.`;
        buttons = [
          {
            text: 'Copy to Clipboard',
            handler: () => {
              navigator.clipboard.writeText(url);
            }
          },
        ];
      }
      const alert = await this.$ionic.alertController.create({
        message,
        buttons,
      });
      alert.present();
    },
    closeBrowser() {
      this.browserModal.dismiss();
    },
  },
  mounted() {
    this.$root.$on('openurl', async (url) => {
      if (window.cordova && window.cordova.InAppBrowser) {
        window.cordova.InAppBrowser.open(encodeURI(url), '_system');
      } else if (!window.cordova) {
        window.open(url, '_blank');
      } else {
        this.showNotice(url);
      }
    });
  },
}
</script>

<style>
  iframe {
    border:none;
  }
  ion-item.unread
  {
    border-left:solid 2px var(--ion-color-primary,#3880ff);
    margin-top:2px;
    margin-bottom:2px;
  }
  .sc-ion-label-ios-s h3, .sc-ion-label-ios-s p, .card-content-ios p
  {
    font-size:16px;
  }
  ion-list-header
  {
    font-weight: bold;
    text-transform: none;
    font-size: 16px;
  }
</style>
