import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue';

import Tabs from './views/Tabs.vue';
import Home from './views/Home.vue';

import CampaignList from './views/campaigns/CampaignList.vue';
import CampaignItem from './views/campaigns/CampaignItem.vue';
import Devotional from './views/campaigns/Devotional.vue';
import Reading from './views/campaigns/Reading.vue';

import NewsList from './views/news/NewsList.vue';
import NewsItem from './views/news/NewsItem.vue';

import TestimonyList from './views/testimonies/TestimonyList.vue';
import TestimonyItem from './views/testimonies/TestimonyItem.vue';

import OnboardingChurch from './views/onboarding/OnboardingChurch.vue';
import OnboardingLanguage from './views/onboarding/OnboardingLanguage.vue';
import OnboardingCountry from './views/onboarding/OnboardingCountry.vue';

import Settings from './views/settings/Settings.vue';
import LanguageSettings from './views/settings/LanguageSettings.vue';
import ChurchSettings from './views/settings/ChurchSettings.vue';
import CountrySettings from './views/settings/CountrySettings.vue';
import About from './views/settings/About.vue';
import Preferences from './views/settings/Preferences.vue';

Vue.use(IonicVueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/onboarding/language',
    name: 'onboarding-language',
    component: OnboardingLanguage,
    meta: {
      onboarding: true,
    },
  },
  {
    path: '/onboarding/country',
    name: 'onboarding-country',
    component: OnboardingCountry,
    meta: {
      onboarding: true,
    },
  },
  {
    path: '/onboarding/church',
    name: 'onboarding-church',
    component: OnboardingChurch,
    meta: {
      onboarding:true,
    },
  },
  {
    path: '/',
    name: 'tabs',
    component: Tabs,
    children: [
      {
        path: 'home',
        name: 'home',
        components: {
          home: Home,
        },
      },
      {
        path: 'campaigns',
        name: 'campaign-list',
        components: {
          campaigns: CampaignList,
        },
      },
      {
        path: 'campaigns/:id',
        name: 'campaign-item',
        components: {
          campaigns: CampaignItem,
        },
      },
      {
        path: 'campaigns/devotional/:date',
        name: 'devotional',
        components: {
          campaigns:Devotional,
        },
      },
      {
        path: 'campaigns/reading/:id',
        name: 'reading',
        components: {
          campaigns: Reading,
        },
      },
      {
        path: 'news',
        name: 'news-list',
        components: {
          news: NewsList,
        },
      },
      {
        path: 'news/:id',
        name: 'news-item',
        components: {
          news: NewsItem,
        },
      },
      {
        path: 'testimonies',
        name: 'testimony-list',
        components: {
          testimony: TestimonyList,
        },
      },
      {
        path: 'testimonies/:id',
        name: 'testimony-item',
        components: {
          testimony: TestimonyItem,
        },
      },
      {
        path: 'settings',
        name: 'settings',
        components: {
          settings: Settings,
        },
      },
      {
        path: 'settings/language',
        name: 'language-settings',
        components: {
          settings: LanguageSettings,
        },
      },
      {
        path: 'settings/church',
        name: 'church-settings',
        components: {
          settings: ChurchSettings,
        }
      },
      {
        path: 'settings/country',
        name: 'country-settings',
        components: {
          settings: CountrySettings,
        }
      },
      {
        path: 'settings/about',
        name: 'about',
        components: {
          settings: About,
        },
      },
      {
        path: 'settings/preferences',
        name: 'preferences',
        components: {
          settings: Preferences,
        },
      },
    ],
  },
];

const router = new IonicVueRouter({ routes });
router.beforeEach((to, from, next) => {
  if(!localStorage.language && to.name !== 'tabs' && to.name !== 'onboarding-language'){
    next({
      name: 'onboarding-language',
    });
  }
  else{
    next();
  }
});

export default router;
