<template>
  <div class="media-progress-bar">
    <ion-range ref="range" min="0" :max="duration" color="positive" @mouseup="onDragEnd" @touchend="onDragEnd" @mousedown="onDragStart" @touchstart="onDragStart">
      <ion-label slot="start">{{displayTime}}</ion-label>
      <ion-label slot="end">{{displayDuration}}</ion-label>
    </ion-range>
  </div>
</template>
<script>
  function formatTime(time) {
    time = Math.floor(time) || 0;
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = time % 60;
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  export default {
    props:["playbacktime","duration"],
    data:function(){
      return {
        dragging:false
      }
    },
    computed:{
      displayTime:function(){
        return formatTime(this.playbacktime);
      },
      displayDuration:function(){
        return formatTime(this.duration);
      },
    },
    watch:{
      playbacktime:function(){
        if(this.playbacktime && !this.dragging){
          this.$refs.range.value = this.playbacktime
        }
      }
    },
    methods:{
      onDragStart:function(){
        this.dragging = true;
      },
      onDragEnd:function(){
        this.dragging = false;
        this.$emit("seek",this.$refs.range.value);
      }
    }
  }
</script>
<style scoped>
  .media-progress-bar
  {
    padding-left:4px;
    padding-right:4px;
  }
  .media-progress-bar ion-range
  {
    padding:0px;
  }
</style>
