<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title>{{this.$route.query.name}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <app-spinner v-if="!reading"></app-spinner>
      <ion-list v-if="reading">
        <ion-item lines="none">
          <ion-label>
            <div v-for="verse in reading.verses" class="one-text-wrap verse">
              <sup style="font-weight:bold;margin-right:3px;">{{verse.verse_id}}</sup>
              <span>{{verse.verse_text}}</span>
            </div>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>{{reading.name}}</p>
            <p>{{reading.copyright}}</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script>
  import $api from "@/services/api";
  import $analytics from "@/services/analytics";
  import AppSpinner from "@/components/AppSpinner";
  import { mapState } from 'vuex';

  export default {
    components:{
      AppSpinner
    },
    data:function(){
      return {
        reading:null
      }
    },
    computed: {
      ...mapState('translations', {
          translate: 'translate',
      }),
    },
    methods:{
      refreshData:function(){
        $api.get("/readings/text/" + this.$route.params.id).then(reading=>{
          this.reading = reading;
        });
      }
    },
    created:function(){
      this.refreshData();
      $analytics.track("bible-reading",this.$route.params.id);
    }
  }
</script>
<style scoped>
  verse
  {
    color: inherit;
    line-height:1.5;
  }
</style>
