const apiroot = process.env.VUE_APP_API_ENDPOINT;

function getHeaders(){
  const headers = {};
  headers['Content-Type'] = 'application/json';
  headers['Accept-Language'] = localStorage.language || 'ENG';
  return headers;
}

function handleGet(path, qs){
  const url = new URL(apiroot + path);
  qs = qs || {};
  Object.keys(qs).forEach(function(key){
    url.searchParams.append(key,qs[key]);
  })
  return fetch(url,{
    method: 'GET',
    cors: 'cors',
    headers: getHeaders(),
  }).then(r => r.json());
};

function handlePost(path, body){
  const url = new URL(apiroot + path);
  return fetch(url, {
    method: 'POST',
    cors: 'cors',
    headers: getHeaders(),
    body: JSON.stringify(body),
  }).then(r => r.json());
};

function handlePut(path, body){
  var url = new URL(apiroot + path);
  return fetch(url, {
    method: 'PUT',
    cors: 'cors',
    headers: getHeaders(),
    body: JSON.stringify(body),
  }).then(r => r.json());
};

function handleDelete(path){
  var url = new URL(apiroot + path);
  return fetch(url, {
    method: 'DELETE',
    cors: 'cors',
    headers: getHeaders()
  }).then(r => r.json());
};

export default {
  get: handleGet,
  post: handlePost,
  put: handlePut,
  delete: handleDelete
};
