<template>
  <div>
    <ion-tabs>
      <ion-tab tab="/home">
        <ion-vue-router name="home" class="router-content"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/campaigns">
        <ion-vue-router name="campaigns" class="router-content"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/news">
        <ion-vue-router name="news"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/testimonies">
        <ion-vue-router name="testimony"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/settings">
        <ion-vue-router name="settings"></ion-vue-router>
      </ion-tab>
      <AudioController></AudioController>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="/home">
          <i class="icons8-home"></i>
          <ion-label>{{ translate.labels['app.today.title'] }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="/campaigns">
          <i class="icons8-clock"></i>
          <ion-label>{{ translate.labels['app.list.title'] }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="/news">
          <i class="icons8-ereader"></i>
          <ion-label>{{ translate.labels['app.news.title'] }}</ion-label>
          <ion-badge v-if="unreadNewsCount">{{ unreadNewsCount}}</ion-badge>
        </ion-tab-button>
        <ion-tab-button tab="/testimonies">
          <i class="icons8-communicate"></i>
          <ion-label>{{ translate.labels['app.testimonies.title'] }}</ion-label>
          <ion-badge v-if="unreadTestimoniesCount">{{ unreadTestimoniesCount }}</ion-badge>
        </ion-tab-button>
        <ion-tab-button tab="/settings">
          <i class="icons8-settings"></i>
          <ion-label>{{ translate.labels['app.settings.title'] }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </div>
</template>
<script>
  import $api from "@/services/api.js";
  import $history from "@/services/history.js";
  import AudioController from "@/components/AudioController.vue";
  import { mapState } from 'vuex';
  import { calendar, informationCircle, map, personCircle } from 'ionicons/icons';

  export default {
    components:{
      AudioController
    },
    computed: {
      ...mapState({
        translate: state => state.translations.translate,
        unreadNewsCount: state => state.global.unreadNewsCount,
        unreadTestimoniesCount: state => state.global.unreadTestimoniesCount,
      }),
    },
    methods:{
      refreshUnreadNewsCount(){
        $api.get("/news").then((news) => {
          this.$store.commit('global/setNews', news);
          this.$store.commit('global/getUnreadNewsCount');
        });
      },
      refreshUnreadTestimoniesCount(){
        $api.get("/testimonies").then((testimonies) => {
          this.$store.commit('global/setTestimonies', testimonies);
          this.$store.commit('global/getUnreadTestimoniesCount');
        });
      },
    },
    created() {
      this.refreshUnreadNewsCount();
      this.refreshUnreadTestimoniesCount();
    },
  }
</script>
<style scoped>
  .router-content {
    padding-bottom: 40px;
  }
  ion-tab-button i {
    font-size: 2.6em;
  }
  ion-tab-button ion-label {
    font-size: var(--size-tab);
  }
  ion-badge
  {
    left: 50px;
    top: 5px;
  }

</style>
