<template>
  <div class="audio-controller">
    <div class="title-bar">
      <span v-if="audio.currentTrack">{{audio.currentTrack.title}}</span>
      <i class="icons8-cancel" @click="close()"></i>
    </div>
    <app-spinner v-if="audio.buffering"></app-spinner>
    <div class="button-bar" v-if="!audio.buffering">
      <i :inactive="!audio.hasPrevious()" @click="previous()" class="icons8-rewind"></i>
      <i v-if="audio.isPlaying()" @click="pause()" class="icons8-pause"></i>
      <i v-if="!audio.isPlaying()" @click="resume()" class="icons8-play"></i>
      <i :inactive="!audio.hasNext()" @click="next()" class="icons8-fast-forward"></i>
    </div>
    <div v-if="!audio.buffering">
      <MediaProgressBar :playbacktime="playbackTime" :duration="duration" @seek="handleSeek"></MediaProgressBar>
    </div>
  </div>
</template>
<script>
  import $audio from "@/services/audio";
  import MediaProgressBar from "@/components/MediaProgressBar.vue";
  import AppSpinner from "@/components/AppSpinner";

  var _audio = new Audio();
  _audio.autoplay = false;

  export default {
    components:{
      MediaProgressBar,
      AppSpinner
    },
    data:function(){
      return {
        audio:$audio,
        playbackTime:null,
        duration:null
      }
    },
    methods:{
      close:function(){
        $audio.pause();
        setTimeout(function(){
          document.body.classList.remove("show-audio-controller");
        });
      },
      pause:function(){
        $audio.pause();
      },
      resume:function(){
        $audio.resume();
      },
      previous:function(){
        $audio.hasPrevious() && $audio.playPrevious();
      },
      next:function(){
        $audio.hasNext() && $audio.playNext();
      },
      handleSeek:function(targetTime){
        $audio.seek(targetTime);
      }
    },
    created:function(){
      $audio.subscribe((e)=>{
        if($audio.state != "paused"){
          document.body.classList.add("show-audio-controller");
        }
        if($audio.currentTrack){
          this.playbackTime = $audio.currentTrack.time;
          this.duration = $audio.currentTrack.duration;
        }
        else{
          this.playbackTime = this.displayDuration = null;
        }
      });
    }
  }
</script>
<style>
  .audio-controller
  {
    visibility: hidden;
  }
  .show-audio-controller .audio-controller
  {
    visibility: visible;
    user-select: none !important;
    -webkit-user-select: none !important;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 150px;
    border-top:solid 1px #ccc;
    background-color:#efefef;
    padding:10px;
  }
  .show-audio-controller ion-tab ion-router-outlet{
    bottom: 100px;
  }
</style>
<style scoped>
  .audio-controller
  {
  }
  .title-bar
  {
    display:flex;
    justify-content: space-between;
    padding:4px;
    font-weight:bold;
  }
  .button-bar
  {
    text-align: center;
  }
  .button-bar ion-icon
  {
    font-size: 40px;
    margin-right: 7px;
    cursor: pointer;
  }
  ion-icon[inactive]
  {
    color:#888;
  }

  .title-bar ion-icon {
    font-size: 35px;
    cursor: pointer;
  }
  i
  {
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
