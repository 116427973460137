<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :text="translate.labels['app.general.backButton']"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ translate.labels['app.settings.country'] }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <country-selector />
    </ion-content>
  </ion-page>
</template>
<script>
import $analytics from '@/services/analytics';
import $api from '@/services/api';
import CountrySelector from '@/components/CountrySelector';
import { mapState } from 'vuex';

export default {
  components: {
    CountrySelector
  },
  computed: {
    ...mapState('translations', {
       translate: 'translate',
    }),
  },
  created() {
    $analytics.track('country-settings-view');
  },
};
</script>
